/**
 * @author Vahagn Lazyan.
 * @since 1.3.0
 */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ClassifierService, ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {FilterBuilder, FilterCriteria} from '@synisys/idm-de-core-frontend';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {KbService, MetaCategory, MetaCategoryId, MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';

import {DynamicCommunicationService} from '../../../services/dynamic-communication.service';
import {StateDto, StateService} from '@synisys/idm-workflow-service-client-js';
import {Observable} from 'rxjs/Observable';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';

@Component({
             moduleId   : module.id + '',
             selector   : 'dynamic-quick-filter',
             templateUrl: './quick-filter.component.html',
           })
@ControlMetadata({
                   template: `
                      <dynamic-quick-filter
                        [categorySystemName]="'%{categorySystemName}'"
                        [filterSystemName]="'%{filterSystemName}'"
                        [filterKey]="'%{filterKey}'">
                      </dynamic-quick-filter>
                   `,
                 })
export class DynamicQuickFilterComponent implements OnInit {

  /**
   * System name of mainEntity to be filtered.
   * @type {string}
   */
  @Input()
  public categorySystemName: string;

  /**
   * System name of {@link MetaField} to filter mainEntity.
   * @type {string}
   */
  @Input()
  public filterSystemName: string;

  /**
   * Key to publish filter by {@link DynamicCommunicationService}.
   * @type {string}
   */
  @Input()
  public filterKey: string;

  /**
   * Event to listen to get created filter.
   * @type {EventEmitter<object>}
   * @default
   */
  @Output()
  public getFilter: EventEmitter<object> = new EventEmitter<object>();

  /**
   * Is used to init view after all needed info is loaded.
   * @type {boolean}
   * @private
   */
  private _isReady = false;

  /**
   * Tabs for filter.
   * @type {Array<ClassifierView>}
   * @private
   */
  private _filterItems: (ClassifierView | StateDto)[];

  private _ids: number[] = [];

  constructor(private kbService: KbService,
              private classifierService: ClassifierService,
              private currentLanguageProvider: CurrentLanguageProvider,
              private dynamicCommunicationService: DynamicCommunicationService,
              private stateService: StateService) {
  }

  get isReady(): boolean {
    return this._isReady;
  }

  get filterItems(): (ClassifierView | StateDto)[] {
    return this._filterItems;
  }

  public ngOnInit() {
    this.kbService.getMetaFields(this.categorySystemName).subscribe((metaFields: MetaField[]) => {
      const metaField: MetaField = metaFields.find((field: MetaField) => {
        return field.getSystemName() === this.filterSystemName;
      });
      if (metaField === undefined) {
        throw Error(`Cannot find field with ${this.filterSystemName} systemName!`);
      }
      if (metaField.getType() === MetaFieldType.CLASSIFIER) {
        this.classifierService.loadClassifiersView(metaField.getCompoundCategorySystemName())
            .subscribe((items: ClassifierView[]) => {
              this._filterItems = items;
              this.handleItems();
              this._isReady = true;
            }, err => console.error(err));

      } else if (metaField.getType() === MetaFieldType.WORKFLOW_STATE) {
        this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(this.categorySystemName))
            .mergeMap((metaCategory: MetaCategory) => {
              return Observable.fromPromise(this.stateService.getStates(metaCategory.getWFProcessId()));
            })
            .subscribe((states: StateDto[]) => {
              this._filterItems = states;
              this.handleItems();
              this._isReady = true;
            }, err => console.error(err));
      } else {
        throw Error(`${this.filterSystemName} systemName is not lookup field for ${this.categorySystemName} entity!`);
      }
    }, err => console.error(err));
  }

  public onFilterChange(tabId: number): void {
    const filterBuilder: FilterBuilder = new FilterBuilder();

    if (tabId !== 0) {
      filterBuilder.is(this.filterSystemName, this._ids[+tabId]);
    }
    const filter: FilterCriteria = filterBuilder.build();

    this.getFilter.emit(filter.toJson());
    this.dynamicCommunicationService.applyQuickFilter(this.filterKey, filter);

  }

  getItemName(item: ClassifierView | StateDto): Observable<string> {
    if (item instanceof StateDto) {
      return this.currentLanguageProvider.getCurrentLanguage().map((language: Language) => {
        return item.getName().getValue(language.getId());
      });
    } else {
      return Observable.of(item.getName());
    }
  }

  public trackByFunc(index: number, item: (ClassifierView | StateDto)) {
    if (item instanceof ClassifierView) {
      return item.id;
    }
    return item.getId();
  }

  private handleItems() {
    this._ids.push(-1);
    this._filterItems.map(item => this._ids.push(item.getId()));
  }

}
