import {mergeMap} from 'rxjs/operators';
/**
 * @author tatevik.marikyan
 * @since 27/07/2018
 */
import {DataService, ServiceResponse} from '@synisys/idm-de-service-client-js';
import {StringTemplate} from '@synisys/idm-common-util-frontend';
import {Injectable} from '@angular/core';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {PagingOptions, SearchParam} from '@synisys/idm-de-core-frontend';
import {HierarchicalSearchFilterOptions, HierarchicalSearchItem} from '../model';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {HierarchicalSearchDeserializationService} from './hierarchical-search-deserialization.service';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {Map} from 'immutable';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/observable/from';

@Injectable()
export class HierarchicalSearchService {

  private ENTITY_SEARCH_SERVICE_URI_KEY = 'entity-search-service-url';

  private URL_HIERARCHICAL_ITEMS_LOAD: StringTemplate =
    StringTemplate.createTemplate`${'serviceUri'}/hierarchicalentities/${'rootCategoryName'}`;

  private entitySearchServiceUri$: Observable<string>;

  private hierarchicalSearchSerializationService: HierarchicalSearchDeserializationService;

  constructor(private applicationPropertiesService: ApplicationPropertiesService,
              private currentLanguageProvider: CurrentLanguageProvider,
              private dataService: DataService,
              private kbService: KbService,
              private languageService: LanguageService) {
    this.entitySearchServiceUri$ =
      from(this.applicationPropertiesService.getProperty(this.ENTITY_SEARCH_SERVICE_URI_KEY));
    this.hierarchicalSearchSerializationService =
      new HierarchicalSearchDeserializationService(currentLanguageProvider, languageService);
  }

  /**
   * Loads Hierarchical Items in given categories with specified root Category
   * @param {Map<string, string>} categoryFieldsMapping
   * @param {string} rootCategoryName
   * @param {HierarchicalSearchFilterOptions} filter
   * @param {PagingOptions} paging
   * @returns {Observable<any>}
   */
  public loadHierarchicalEntities(categoryFieldsMapping: Map<string, string>, rootCategoryName: string,
                                  filter: HierarchicalSearchFilterOptions,
                                  paging?: PagingOptions): Observable<ServiceResponse<HierarchicalSearchItem[]>> {
    return this.entitySearchServiceUri$.pipe(
               mergeMap((serviceUri: string) => {
                 const url: string = this.URL_HIERARCHICAL_ITEMS_LOAD.replaceTemplate({
                                                                                        serviceUri,
                                                                                        rootCategoryName
                                                                                      });

                 const queryParams: SearchParam<any>[] = [];

                 if (paging !== undefined && paging !== null) {
                   queryParams.push(new SearchParam('paging', paging));
                 }

                 queryParams.push(new SearchParam('filter', filter || {}));

                 const hierarchicalEntitiesData$ = this.dataService.load(url, queryParams);
                 return this.hierarchicalSearchSerializationService.deserializeEntities(hierarchicalEntitiesData$,
                                                                                        categoryFieldsMapping);
               }));
  }

}
