import {Component, Inject, OnInit} from '@angular/core';
import {createLogger} from '../../utilities';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {
    LanguagesState,
    languageStoreManager,
} from '@synisys/skynet-store-languages-api';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {
    Language,
    MultilingualString,
    MultilingualStringBuilder,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {first, map, switchMap, tap} from 'rxjs/operators';
import {
    MessageKeyFactory,
    messageStoreManager,
} from '@synisys/skynet-store-messages-api';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {noop} from 'rxjs/util/noop';
import './export-copy-template-dialog.component.scss';
import {isEmpty} from 'lodash';

const LOGGER = createLogger('export-copy-template-dialog');

@Component({
    moduleId: module.id + '',
    selector: 'export-copy-template-dialog',
    templateUrl: 'export-copy-template-dialog.component.html',
})
export class ExportCopyTemplateDialogComponent implements OnInit {
    public languages$: Observable<Language[]>;
    public currentLanguage$: Observable<number>;
    public template_name: MultilingualString = new MultilingualStringBuilder().build();
    public isReady = false;

    constructor(
        private dialogRef: MatDialogRef<
            ExportCopyTemplateDialogComponent,
            MultilingualString | undefined
        >,
        private store: Store<LanguagesState>,
        @Inject(MAT_DIALOG_DATA) public original_name: MultilingualString
    ) {}

    public cancelAction(): void {
        this.dialogRef.close(undefined);
    }

    public copyAction(): void {
        this.dialogRef.close(this.template_name);
    }

    public getMessageKey(languages: Language[]): string {
        if (
            languages.some(l => isEmpty(this.template_name.getValue(l.getId())))
        ) {
            return 'ng-controls_ui.labels.field-is-required';
        }
        return '';
    }

    public ngOnInit(): void {
        this.languages$ = languageStoreManager
            .selectAll(this.store)
            .pipe(map(languages => languages.toList().toArray()));
        this.currentLanguage$ = this.store.select(
            s => s.languages.currentLanguageId
        );
        this.languages$
            .pipe(
                switchMap(languages =>
                    combineLatest(
                        ...languages.map(language =>
                            messageStoreManager.selectOne(
                                this.store,
                                MessageKeyFactory({
                                    name: 'de.template.duplicate.name_prefix',
                                    languageId: language.getId(),
                                })
                            )
                        )
                    ).pipe(
                        first(),
                        map(messages => {
                            const builder = new MultilingualStringBuilder();
                            languages.forEach((l, i) =>
                                builder.withValueForLanguage(
                                    l.getId(),
                                    messages[i].value +
                                        this.original_name.getValue(l.getId())
                                )
                            );
                            return builder.build();
                        }),
                        tap(name => {
                            this.template_name = name;
                            this.isReady = true;
                        })
                    )
                )
            )
            .subscribe(noop, err => LOGGER.error(err));
    }
}
