import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Observable} from 'rxjs/Observable';
import './header.component.scss';
import {FormActions} from '../../models';
import {
    ExportTemplate,
    HeaderSettingsComponent,
} from './header-settings.component';
import {
    UserHelpItem,
    userHelpItemFromObject,
} from '../../models/user-help-item.model';

@Component({
    moduleId: module.id + '',
    selector: 'sis-header',
    templateUrl: 'header.component.html',
})
@ControlMetadata({
    template: `
      <sis-header
            [id]="'%{id}'"
            [entity]="entity"
            [categoryName]="category"
            [isView]="actions.isViewForm()"
            [caption]="category"
            [form]="form"
            [formActions]="actions"
            [userHelpItemsAsObjects]="%{userHelpItems}"
            [exportTemplates]="%{exportTemplates}"
            [workflowActions]="workflowActions"
            [currentLanguageId]="currentLanguageId"
            [labelMessage]="%{labelMessage}"
            [title_msg]="%{title_msg}"
            [withDeleteButton]="%{withDeleteButton}"
            [withEditButton]="%{withEditButton}"
            [actionNotifier]="actionNotifier"
            [hasEditPermission]="%{hasEditPermission}"
            [isReadonly]="%{isReadonly}"
            (onWfSelect)="%{onWfSelect}"
            (editClick)="%{editClick}"
            (cancelEvent)="%{cancelEvent}"
            (saveEvent)="%{saveEvent}"
            (saveCloseEvent)="%{saveCloseEvent}">
      </sis-header>
  `,
    settings: {
        main: HeaderSettingsComponent,
    },
    isHeader: true,
    name: 'Desktop Header',
    nameKey: 'de.controls.desktop_header.title',
    defaultActions: {
        hasEditPermission: 'hasEditPermission',
        onWfSelect: 'onDoWorkflowAction',
        editClick: 'toDefaultEditForm',
        cancelEvent: 'onCancel',
        saveEvent: 'onSave',
        saveCloseEvent: 'onSaveAndClose',
    },
    isFieldBound: true,
})
export class HeaderComponent {
    @Input()
    public id: string;

    @Input()
    public entity: Entity;

    @Input()
    public categoryName: string;

    @Input()
    public isView = false;

    @Input()
    public isReadonly = false;

    @Input()
    public caption: string;

    @Input()
    public title_msg: string;

    @Input()
    public userHelpItems: UserHelpItem[];

    @Input()
    set userHelpItemsAsObjects(values: object[]) {
        this.userHelpItems = values ? values.map(userHelpItemFromObject) : [];
    }

    @Input()
    public exportTemplates: ExportTemplate[];

    @Input()
    public workflowActions: ActionDto[] = [];

    @Input()
    public currentLanguageId: number;

    @Input()
    public labelMessage: string;

    @Input()
    public hasEditPermission: boolean;

    @Input()
    public withDeleteButton: boolean;

    @Input()
    public withEditButton: boolean;

    @Input()
    // tslint:disable-next-line:no-any
    public actionNotifier: Observable<any>;

    @Input()
    public form: object;

    @Input()
    public formActions: FormActions;

    // tslint:disable-next-line:no-output-on-prefix
    @Output()
    public onWfSelect: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public editClick: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public cancelEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public saveEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public saveCloseEvent: EventEmitter<void> = new EventEmitter<void>();

    public getIsView(): boolean {
        return this.isView !== undefined ? this.isView : this.isReadonly;
    }
}
