/**
 * @author Vahagn Lazyan.
 * @since 1.2.0
 */
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';

import {
    ClassifierService,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {FilterBuilder, FilterCriteria} from '@synisys/idm-de-core-frontend';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {
    HierarchicalMetaCategoryId,
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';

import {
    DynamicCommunicationService,
    PortfolioCommunicationDto,
} from '../../services';
import {isArray} from 'lodash';
import './filter.component.scss';
/**
 *
 */
@Component({
    moduleId: module.id + '',
    selector: 'dynamic-filter',
    templateUrl: 'filter.component.html',
})
@ControlMetadata({
    template: `
                      <dynamic-filter
                          [categorySystemName]="'%{categorySystemName}'"
                          [filterFields]="%{filterFields}"
                          [filterKey]="'%{filterKey}'"
                          %{#filterWithData} [filterWithData]="%{filterWithData}"%{/filterWithData}
                          %{#getFilter} (getFilter)="%{getFilter}"%{/getFilter}
                          %{#filterWithDataChange} (filterWithDataChange)="%{filterWithDataChange}"%{/filterWithDataChange}>
                      </dynamic-filter>
                   `,
})
export class DynamicFilterComponent implements OnInit {
    // Expose enums to use in template.
    public metaFieldType = MetaFieldType;
    /**
     * Category system name to create filter for.
     * @type {string}
     */
    @Input()
    public categorySystemName: string;
    /**
     * Object for column system names, where keys are field system names,
     * values are searchField names.
     * @type {Array<string>}
     */
    @Input()
    public filterFields: (string | object)[];
    /**
     * Key to publish filter by {@link DynamicCommunicationService}.
     * @type {string}
     */
    @Input()
    public filterKey: string;
    @Input()
    public filterWithData: Object = {};
    /**
     * Event to listen to get created filter.
     * @type {EventEmitter<object>}
     * @default
     */
    @Output()
    public getFilter: EventEmitter<object> = new EventEmitter<object>();
    @Output()
    public filterWithDataChange: EventEmitter<object> = new EventEmitter<
        object
    >();
    /**
     * Map, containing Values for combo-box's.
     * Key is {@link MetaField}'s systemName.
     * Value is {@link Array<ClassifierView>}.
     * @type {Map<string, Array<ClassifierView>>}
     * @private
     */
    private _classifiers: Map<string, ClassifierView[]>;
    /**
     * Language used by system, at this moment.
     * @type {Language}
     * @private
     */
    private _currentLanguage: Language;
    /**
     * {@link MetaField}s filtered by {@link filterFields}.
     * @type {Array<MetaField>}
     * @private
     */
    private _filteredMetaFields: MetaField[];
    /**
     *
     * Filtered entitysearch fields
     * @type {Map<string, string[]>}
     * private
     */

    private _filterEntitySearchFields: Map<string, string[]> = new Map<
        string,
        string[]
    >();
    /**
     * Values of filter.
     * @type {Map<string, any>}
     * @default
     * @private
     */
    private _filterValues: Map<string, any> = new Map();
    /**
     * Array of {@link HierarchicalMetaCategoryId} filtered with {@link filterFields}.
     * Used to reset field values hierarchically.
     * @type {Array<HierarchicalMetaCategoryId>}
     * @private
     */
    private filteredHierarchicalMetaCategories: HierarchicalMetaCategoryId[];
    /**
     * Is used to init view after all needed info is loaded.
     * @type {boolean}
     * @default
     * @private
     */
    private _isReady = false;
    /**
     * Available {@link Language}s.
     * @type {Array<Language>}
     * @private
     */
    private _languages: Language[];
    /**
     * Grouped metaFields, to draw layout.
     * @type {Array<Array<MetaField>>}
     * @private
     */
    private _layoutMetaFields: MetaField[][];
    /**
     *
     * @type {Map<any, any>}
     * @default
     * @private
     */
    private systemNameSearchNameMap: Map<string, string> = new Map();

    constructor(
        private kbService: KbService,
        private classifierService: ClassifierService,
        private languageService: LanguageService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private dynamicCommunicationService: DynamicCommunicationService
    ) {}

    get classifiers(): Map<string, ClassifierView[]> {
        return this._classifiers;
    }

    get currentLanguage(): Language {
        return this._currentLanguage;
    }

    get filterValues(): Map<string, any> {
        return this._filterValues;
    }

    get isReady(): boolean {
        return this._isReady;
    }

    get languages(): Language[] {
        return this._languages;
    }

    get layoutMetaFields(): MetaField[][] {
        return this._layoutMetaFields;
    }

    get filterEntitySearchFields(): Map<string, string[]> {
        return this._filterEntitySearchFields;
    }

    @HostListener('keyup.enter')
    public onEnter() {
        this.search();
    }

    public ngOnInit() {
        const languages$ = this.languageService.getInputLanguages();
        const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();
        const metaFields$ = this.kbService.getMetaFields(
            this.categorySystemName
        );
        const hierarchicalCategories$ = this.kbService.getHierarchicalMetaCategoryIds();

        Observable.combineLatest(
            languages$,
            currentLanguage$,
            metaFields$,
            hierarchicalCategories$
        ).subscribe(
            (data: any[]) => {
                this._languages = data[0];
                this._currentLanguage = data[1];

                this._filteredMetaFields = this.filterMetaFieldsBy(
                    data[2],
                    this.filterFields
                );

                this.filteredHierarchicalMetaCategories = this.createFilteredHierarchy(
                    data[3],
                    this._filteredMetaFields
                );

                this._layoutMetaFields = this.classifyMetaFields(
                    this._filteredMetaFields
                );

                this.processSearchFields(this._filteredMetaFields);

                if (!this.filterWithData['classifiers']) {
                    this._classifiers = this.loadLookupFieldsFrom(
                        this._filteredMetaFields,
                        this.filteredHierarchicalMetaCategories
                    );
                } else {
                    this._classifiers = this.filterWithData['classifiers'];
                }

                if (!this.filterWithData['filterData']) {
                    this.initEmptyValueMap();
                } else {
                    this._filterValues = this.filterWithData['filterData'];
                }

                this._isReady = true;
            },
            err => console.error(err)
        );
    }

    /**
     * Sets value in filter, and resets/loads child classifiers.
     * @param event - value to set in filter.
     * @param {string} systemName - systemName of a classifier to change.
     */
    public onClassifierChange(event: any, systemName: string): void {
        this._filterValues.set(systemName, event);
        this.resetChildrenOf(systemName);
        if (event !== null) {
            this.loadChildClassifiers(systemName, event);
        }
    }

    /**
     * Creates {@link FilterCriteria} with {@link createFilterFrom}
     * and emits value via {@link getFilter}.
     */
    public search(): void {
        const filter: FilterCriteria = this.createFilterFrom(
            this.systemNameSearchNameMap,
            this.filterValues
        );
        this.filterWithData['filter'] = filter.toJson();
        this.filterWithData['filterData'] = this.filterValues;
        this.filterWithData['classifiers'] = this.classifiers;

        this.getFilter.emit(filter.toJson());
        this.filterWithDataChange.emit(this.filterWithData);
        this.dynamicCommunicationService.portfolioPropertiesSubject.next(
            new PortfolioCommunicationDto(filter, this.filterKey)
        );
    }

    /**
     * Resets Filter values.
     */
    public reset(): void {
        this.initEmptyValueMap();
        this.filteredHierarchicalMetaCategories.forEach(
            (field: HierarchicalMetaCategoryId) => {
                this.resetBranchCategories(field);
            }
        );
        this.getFilter.emit(null);
        this.filterWithDataChange.emit({});
        this.dynamicCommunicationService.portfolioPropertiesSubject.next(
            new PortfolioCommunicationDto(null, this.filterKey)
        );
    }

    public getBooleanFieldValue(fieldSystemName: string) {
        if (this._filterValues.get(fieldSystemName) === null) {
            this._filterValues.set(fieldSystemName, false);
        }
        return this._filterValues.get(fieldSystemName);
    }

    public isOutOfHierarchy(metaField: MetaField): boolean {
        return this.filteredHierarchicalMetaCategories.some(
            hierarchicalMetaCategory =>
                hierarchicalMetaCategory.getSystemName() ===
                    metaField.getCompoundCategorySystemName() &&
                hierarchicalMetaCategory.getChildren().length === 0
        );
    }

    public trackByFunc(index: number, layoutMetaField: MetaField): string {
        return layoutMetaField.getMetaFieldId().getSystemName();
    }

    /**
     * Creates filter using {@link FilterBuilder}.
     * @param {Map<string, string>} fieldMap - systemName-searchName map.
     * @param {Map<string, any>} filterValues - values for filter.
     * @returns {object}
     * @private
     */
    private createFilterFrom(
        fieldMap: Map<string, string>,
        filterValues: Map<string, any>
    ): FilterCriteria {
        const filterBuilder = new FilterBuilder();

        Array.from(fieldMap.keys()).forEach((systemName: string) => {
            const metaField: MetaField = this.findMetaFieldWithSystemName(
                systemName,
                this._filteredMetaFields
            );
            switch (metaField.getType()) {
                case MetaFieldType.MULTILINGUAL_STRING: {
                    let filterForMultilingualString: FilterBuilder = null;
                    this.languages.forEach(
                        (language: Language, index: number) => {
                            const value = this.extractValueFrom(
                                filterValues
                                    .get(systemName)
                                    .getValue(language.getId())
                            );
                            if (index === 0) {
                                if (value !== null) {
                                    filterForMultilingualString = this.createFilterForText(
                                        `${fieldMap.get(
                                            systemName
                                        )}.${language.getId()}`,
                                        value as string
                                    );
                                }
                            } else {
                                if (value !== null) {
                                    if (filterForMultilingualString === null) {
                                        filterForMultilingualString = this.createFilterForText(
                                            `${fieldMap.get(
                                                systemName
                                            )}.${language.getId()}`,
                                            value as string
                                        );
                                    } else {
                                        const filter = this.createFilterForText(
                                            `${fieldMap.get(
                                                systemName
                                            )}.${language.getId()}`,
                                            value as string
                                        );
                                        filterForMultilingualString.or(
                                            filter.build()
                                        );
                                    }
                                }
                            }
                        }
                    );
                    if (filterForMultilingualString !== null) {
                        filterBuilder.and(filterForMultilingualString.build());
                    }
                    break;
                }
                case MetaFieldType.STRING: {
                    const value = this.extractValueFrom(
                        filterValues.get(systemName)
                    );
                    if (value !== null) {
                        filterBuilder.and(
                            this.createFilterForText(
                                fieldMap.get(systemName),
                                value as string
                            ).build()
                        );
                    }
                    break;
                }
                default: {
                    const value = this.extractValueFrom(
                        filterValues.get(systemName)
                    );
                    if (value !== null && value !== undefined) {
                        if (isArray(value)) {
                            if (value.length !== 0) {
                                filterBuilder.in(
                                    fieldMap.get(systemName),
                                    value
                                );
                            }
                        } else {
                            filterBuilder.is(fieldMap.get(systemName), value);
                        }
                    }
                }
            }
        });

        return filterBuilder.build();
    }

    private createFilterForText(
        searchName: string,
        searchValue: string
    ): FilterBuilder {
        const filter = new FilterBuilder();
        filter
            .starts(searchName, searchValue)
            .or(
                new FilterBuilder()
                    .contains(searchName, `${searchValue}`)
                    .build()
            );
        return filter;
    }

    /**
     * Filters CategoryHierarchy from given Hierarchy and MetaFields.
     * @param {Array<HierarchicalMetaCategoryId>} metaCategories - hierarchy of categories to filter.
     * @param {Array<MetaField>} metaFields - metaFields to filter with.
     * @returns {Array<HierarchicalMetaCategoryId>} - filtered Hierarchy.
     * @private
     */
    private createFilteredHierarchy(
        metaCategories: HierarchicalMetaCategoryId[],
        metaFields: MetaField[]
    ): HierarchicalMetaCategoryId[] {
        const filteredMetaCategoryHierarchy: HierarchicalMetaCategoryId[] = [];
        metaCategories.forEach((category: HierarchicalMetaCategoryId) => {
            const metaField: MetaField = this.findMetaFieldWithCompoundCategorySystemName(
                category.getSystemName(),
                metaFields
            );
            if (metaField === undefined) {
                filteredMetaCategoryHierarchy.push(
                    ...this.createFilteredHierarchy(
                        category.getChildren(),
                        metaFields
                    )
                );
            } else {
                const currentHierarchy = new HierarchicalMetaCategoryId(
                    category.getSystemName(),
                    category.getIsRoot(),
                    this.createFilteredHierarchy(
                        category.getChildren(),
                        metaFields
                    )
                );
                filteredMetaCategoryHierarchy.push(currentHierarchy);
            }
        });
        return filteredMetaCategoryHierarchy;
    }

    /**
     * Loads first depth lookup fields.
     * Depth is determined from {@param categories}.
     * @param {Array<MetaField>} metaFields - metaFields to load fields from.
     * @param {Array<HierarchicalMetaCategoryId>} categories - used to determine depth of category.
     * @returns {Map<string, Array<ClassifierView>>}
     * @private
     */
    private loadLookupFieldsFrom(
        metaFields: MetaField[],
        categories: HierarchicalMetaCategoryId[]
    ): Map<string, ClassifierView[]> {
        const classifiers: Map<string, ClassifierView[]> = new Map();

        metaFields
            .filter((metaField: MetaField) => {
                return (
                    metaField.getType() === MetaFieldType.CLASSIFIER ||
                    metaField.getType() === MetaFieldType.MULTI_SELECT
                );
            })
            .forEach((metaField: MetaField) => {
                const metaCategory: HierarchicalMetaCategoryId = categories.find(
                    (category: HierarchicalMetaCategoryId) => {
                        return (
                            category.getSystemName() ===
                            metaField.getCompoundCategorySystemName()
                        );
                    }
                );
                if (metaCategory === undefined) {
                    classifiers.set(metaField.getSystemName(), []);
                } else {
                    this.classifierService
                        .loadClassifiersView(
                            metaField.getCompoundCategorySystemName()
                        )
                        .subscribe(
                            (views: ClassifierView[]) => {
                                classifiers.set(
                                    metaField.getSystemName(),
                                    views
                                );
                            },
                            err => console.error(err)
                        );
                }
            });

        return classifiers;
    }

    /**
     * Loads child classifiers for given systemName.
     * Sets them in {@link _classifiers}.
     * @param {string} systemName - {@link MetaField}s systemName.
     * @param {number} parentId - id of parent to load children with.
     * @private
     */
    private loadChildClassifiers(systemName: string, parentId: number): void {
        const metaField: MetaField = this.findMetaFieldWithSystemName(
            systemName,
            this._filteredMetaFields
        );
        this.getBranchFor(metaField, this.filteredHierarchicalMetaCategories)
            .getChildren()
            .forEach((child: HierarchicalMetaCategoryId) => {
                this.classifierService
                    .loadClassifiersViewByParent(
                        child.getSystemName(),
                        parentId
                    )
                    .subscribe(
                        (children: ClassifierView[]) => {
                            const field: MetaField = this.findMetaFieldWithCompoundCategorySystemName(
                                child.getSystemName(),
                                this._filteredMetaFields
                            );
                            this._classifiers.set(
                                field.getSystemName(),
                                children.length > 0 ? children : null
                            );
                            return children;
                        },
                        err => console.error(err)
                    );
            });
    }

    /**
     * Filters {@link MetaField}s by systemName.
     * @param {Array<MetaField>} metaFields - array to filter from.
     * @param {Array<string>} filterFields - systemNames to filter with.
     * @returns {Array<MetaField>}
     * @private
     */
    private filterMetaFieldsBy(
        metaFields: MetaField[],
        filterFields: (string | object)[]
    ): MetaField[] {
        return filterFields.map((filterField: string | object) => {
            const metaField: MetaField = this.findMetaFieldWithSystemName(
                filterField,
                metaFields
            );
            if (metaField === undefined) {
                throw Error(
                    `Cannot find field with ${filterField} systemName.`
                );
            }
            return metaField;
        });
    }

    /**
     * Resets children of given category.
     * @param {string} systemName - systemName of categories {@link MetaField}.
     * @private
     */
    private resetChildrenOf(systemName: string): void {
        const metaField: MetaField = this.findMetaFieldWithSystemName(
            systemName,
            this._filteredMetaFields
        );
        this.resetBranchCategories(
            this.getBranchFor(
                metaField,
                this.filteredHierarchicalMetaCategories
            )
        );
    }

    /**
     * Recursively resets child category values and classifiers of given {@link HierarchicalMetaCategoryId}.
     * @param {HierarchicalMetaCategoryId} category - branch.
     */
    private resetBranchCategories(category: HierarchicalMetaCategoryId): void {
        category.getChildren().forEach((child: HierarchicalMetaCategoryId) => {
            const metaField: MetaField = this.findMetaFieldWithCompoundCategorySystemName(
                child.getSystemName(),
                this._filteredMetaFields
            );
            this._filterValues.set(metaField.getSystemName(), null);
            this._classifiers.set(metaField.getSystemName(), []);
            this.resetBranchCategories(child);
        });
    }

    /**
     * Returns {@link HierarchicalMetaCategoryId} from {@param hierarchy} by systemName of {@link MetaCategory}.
     * In this cate it is compoundCategorySystemName of {@link MetaField}.
     * @param {MetaField} metaField - metaField, which is root for a hierarchy.
     * @param {Array<HierarchicalMetaCategoryId>} hierarchy - given hierarchy to search in.
     * @returns {HierarchicalMetaCategoryId}
     * @private
     */
    private getBranchFor(
        metaField: MetaField,
        hierarchy: HierarchicalMetaCategoryId[]
    ): HierarchicalMetaCategoryId {
        let branch: HierarchicalMetaCategoryId = null;
        hierarchy.some((category: HierarchicalMetaCategoryId) => {
            if (
                category.getSystemName() ===
                metaField.getCompoundCategorySystemName()
            ) {
                branch = category;
                return true;
            } else if (category.getChildren().length > 0) {
                branch = this.getBranchFor(metaField, category.getChildren());
                return branch !== null;
            }
        });
        return branch;
    }

    /**
     * Extracts value from given value with some logic.
     * @param item - given value
     * @returns {string | number} - extracted value.
     * @private
     */
    private extractValueFrom(item: any): string | number | number[] {
        if (item instanceof ClassifierView) {
            return item.id;
        } else if (typeof item === 'string') {
            return item.trim() === '' ? null : item.trim();
        } else {
            return item;
        }
    }

    /**
     * Initializes map with empty values.
     * Empty values are determined with {@link createEmptyValueFor} function.
     * @private
     */
    private initEmptyValueMap(): void {
        this._filteredMetaFields.forEach((metaField: MetaField) => {
            this._filterValues.set(
                metaField.getSystemName(),
                this.createEmptyValueFor(metaField)
            );
        });
    }

    /**
     * Creates empty value by {@link MetaField}.
     * @param {MetaField} metaField
     * @returns {any}
     * @private
     */
    private createEmptyValueFor(metaField: MetaField): any {
        switch (metaField.getType()) {
            case MetaFieldType.MULTILINGUAL_STRING: {
                return MultilingualString.newBuilder()
                    .withValueForLanguages(
                        this._languages.map(language => language.getId()),
                        null
                    )
                    .build();
            }
            case MetaFieldType.MAIN_ENTITY: {
                return [];
            }
            default:
                return null;
        }
    }

    /**
     * Returns {@link MetaField} with matching systemName.
     * @param {string} systemName - systemName of searched {@link MetaField}.
     * @param {Array<MetaField>} metaFields - array to search in.
     * @returns {MetaField}
     * @private
     */
    private findMetaFieldWithSystemName(
        systemNameValue: string | object,
        metaFields: MetaField[]
    ): MetaField {
        return metaFields.find((metaField: MetaField) => {
            if (typeof systemNameValue === 'object') {
                const key: string = Object.keys(systemNameValue)[0];
                if (metaField.getSystemName() === key) {
                    this._filterEntitySearchFields.set(
                        key,
                        systemNameValue[key]
                    );
                    return true;
                }
            }
            return metaField.getSystemName() === (systemNameValue as string);
        });
    }

    /**
     * Returns {@link MetaField} with matching compoundCategorySystemName.
     * @param {string} systemName - compoundCategorySystemName of searched {@link MetaField}.
     * @param {Array<MetaField>} metaFields - array to search in.
     * @returns {MetaField}
     * @private
     */
    private findMetaFieldWithCompoundCategorySystemName(
        systemName: string,
        metaFields: MetaField[]
    ): MetaField {
        return metaFields.find((metaField: MetaField) => {
            return metaField.getCompoundCategorySystemName() === systemName;
        });
    }

    /**
     * Classifies {@link MetaField}s to arrays to draw layout.
     * Dependent fields are generated in new Array.
     * @param {Array<MetaField>} metaFields
     * @returns {Array<Array<MetaField>>}
     */
    private classifyMetaFields(metaFields: MetaField[]): MetaField[][] {
        const classifiedMetaFields: MetaField[][] = [[]];

        metaFields.forEach((metaField: MetaField) => {
            const category: HierarchicalMetaCategoryId = this.filteredHierarchicalMetaCategories.find(
                (field: HierarchicalMetaCategoryId) => {
                    return (
                        field.getSystemName() ===
                        metaField.getCompoundCategorySystemName()
                    );
                }
            );
            if (category === undefined || category.getChildren().length === 0) {
                classifiedMetaFields[classifiedMetaFields.length - 1].push(
                    metaField
                );
            } else {
                classifiedMetaFields.push([metaField]);
            }
        });

        return classifiedMetaFields;
    }

    private processSearchFields(fields: MetaField[]): void {
        this.systemNameSearchNameMap = new Map();
        fields.forEach((field: MetaField) => {
            if (!this.systemNameSearchNameMap.has(field.getSystemName())) {
                this.systemNameSearchNameMap.set(
                    field.getSystemName(),
                    field.getSystemName()
                );
            }
        });
    }
}
