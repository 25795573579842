import {
    Component,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import './header-export-button.component.scss';
import {
    ExportTemplate,
    Extension,
} from '../header-information/header-settings.component';
import {first, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {
    CategoryTemplateViewModel,
    ExportTemplatesService,
    PersonalizedTemplate,
    PersonalizedTemplateView,
    Template,
} from '@synisys/idm-export-templates-service-client-js';
import {
    LanguagesState,
    languageStateKey,
} from '@synisys/skynet-store-languages-api';
import {Store} from '@ngrx/store';
import {ExportService} from '@synisys/idm-export-client-js';
import {Observable} from 'rxjs/Observable';
import {noop} from 'rxjs/util/noop';
import {Entity} from '@synisys/idm-de-core-frontend';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {TemplatesData} from '@synisys/idm-export-templates-service-client-js/api/model/templates-data.model';
import {flatMap, groupBy, isNil, values, isEmpty} from 'lodash';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {createLogger} from '../utilities';
import {FormActions} from '../../models/form-actions';
import {MatDialog, MatMenuTrigger} from '@angular/material';
import {DeleteConfirmDialogComponent} from '../delete-confirm-dialog/delete-confirm-dialog.component';
import {of} from 'rxjs/observable/of';
import {
    extensionToExportType,
    FormExportTemplate,
    formTemplateFromPersonalized,
} from './template.utilities';
import {HelperDirective} from '../helper.directive';
import {notNil} from '@synisys/skynet-store-utilities';
import {ExportCopyTemplateDialogComponent} from './export-copy-template/export-copy-template-dialog.component';
import {ExportDataLoaderService} from './export-data-loader.service';

export type ActionType = 'DOWNLOAD' | 'EDIT' | 'COPY' | 'DELETE';

const LOGGER = createLogger('header-export-button.component');

@Component({
    moduleId: module.id + '',
    selector: 'header-export-button',
    templateUrl: 'header-export-button.component.html',
})
export class HeaderExportButtonComponent implements OnInit {
    public readonly classNameMap: {[key in Extension]: string} = {
        RTF: 'dc-export-menu__title--word',
        XLS: 'dc-export-menu__title--excel',
        PDF: 'dc-export-menu__title--pdf',
    };

    public allTemplates$: Observable<FormExportTemplate[]>;

    @Input()
    public categoryName: string;
    @Input()
    public entity: Entity;
    @Input()
    public form: object;
    @Input()
    public formActions: FormActions;
    @Input()
    set exportTemplates(exportTemplates: ExportTemplate[]) {
        if (!isEmpty(exportTemplates)) {
            this.exportTemplates$.next(exportTemplates);
        }
    }
    public selectedIndex = 0;
    public actionType: ActionType = 'DOWNLOAD';
    @ViewChildren(HelperDirective)
    public actions: QueryList<HelperDirective>;
    @ViewChild(MatMenuTrigger)
    private matMenuTrigger: MatMenuTrigger;
    private readonly allActionsTypes: ActionType[] = [
        'DOWNLOAD',
        'EDIT',
        'COPY',
        'DELETE',
    ];

    private currentLanguage$ = this.store.select(
        languageStateKey,
        'currentLanguageId'
    );

    private exportTemplates$ = new BehaviorSubject<ExportTemplate[]>([]);
    private reload$ = new BehaviorSubject<void>(undefined);
    private readonly actionTypeHandlers: {
        [key in ActionType]: (item: FormExportTemplate) => void;
    } = {
        DOWNLOAD: this.download,
        EDIT: this.openTemplate,
        COPY: this.copy,
        DELETE: this.remove,
    };

    constructor(
        private store: Store<LanguagesState>,
        private exportTemplatesService: ExportTemplatesService,
        private exportService: ExportService,
        private exportDataLoader: ExportDataLoaderService,
        private matDialog: MatDialog
    ) {}

    public ngOnInit(): void {
        if (isNil(this.form['metaForm'])) {
            return;
        }
        this.allTemplates$ = combineLatest(
            this.currentLanguage$.pipe(
                switchMap((currentLanguage: number) =>
                    this.reload$.pipe(
                        switchMap(() =>
                            combineLatest(
                                this.exportTemplatesService.loadTemplatesDataByCategoryName(
                                    this.categoryName,
                                    currentLanguage
                                ),
                                this.exportTemplatesService.loadPersonalizedTemplates(
                                    currentLanguage,
                                    Number(this.form['metaForm'].formId)
                                )
                            )
                        )
                    )
                )
            ),
            this.exportTemplates$
        ).pipe(
            map(
                ([[templateViews, templatesData], exportTemplates]: [
                    [CategoryTemplateViewModel[], TemplatesData],
                    ExportTemplate[]
                ]) => {
                    const existingTemplates = new Map<
                        string,
                        CategoryTemplateViewModel
                    >(
                        templateViews.map((templateView): [
                            string,
                            CategoryTemplateViewModel
                        ] => [templateView.id, templateView])
                    );

                    const dictionary = groupBy(
                        templatesData.templateViews as PersonalizedTemplateView[],
                        temp => temp.originalTemplateUUId
                    );
                    const result: FormExportTemplate[] = [];
                    exportTemplates
                        .filter(exportTemplate =>
                            existingTemplates.has(exportTemplate.id)
                        )
                        .forEach(exportTemplate => {
                            result.push({
                                id: exportTemplate.id,
                                name:
                                    existingTemplates.get(exportTemplate.id)
                                        .name || '',
                                extension: exportTemplate.extension,
                                isPersonalized: false,
                            });
                            if (dictionary[exportTemplate.id]) {
                                result.push(
                                    ...dictionary[exportTemplate.id]
                                        .sort(
                                            (a, b) =>
                                                a.createdOn.getDate() -
                                                b.createdOn.getDate()
                                        )
                                        .map(formTemplateFromPersonalized)
                                );
                                delete dictionary[exportTemplate.id];
                            }
                        });

                    result.push(
                        ...flatMap(values(dictionary), value =>
                            value
                                .sort(
                                    (a, b) =>
                                        a.createdOn.getDate() -
                                        b.createdOn.getDate()
                                )
                                .map(formTemplateFromPersonalized)
                        )
                    );

                    return result;
                }
            )
        );
    }

    public openTemplate(item: FormExportTemplate): void {
        if (this.formActions && this.formActions.editExportTemplate) {
            this.exportTemplatesService
                .loadPersonalizedTemplate(item.id)
                .pipe(
                    first(),
                    tap(template => {
                        this.formActions.editExportTemplate(template);
                    })
                )
                .subscribe(noop, LOGGER.error);
        }
        this.matMenuTrigger.closeMenu();
    }

    public download(item: FormExportTemplate): void {
        const template$ = item.isPersonalized
            ? this.exportTemplatesService.loadPersonalizedTemplate(item.id)
            : this.exportTemplatesService.loadTemplate(item.id);
        combineLatest(
            template$,
            this.exportDataLoader.getExportData(
                this.categoryName,
                this.entity.getId()
            ),
            this.currentLanguage$
        )
            .pipe(
                first(),
                mergeMap(([template, data, currentLanguage]) => {
                    return this.exportService.exportWithCategory({
                        categorySystemName: this.categoryName,
                        categoryItemId: this.entity.getInstanceId(),
                        languageId: currentLanguage,
                        exportType: extensionToExportType(item.extension),
                        layoutName: template.uuid,
                        fileName: template.name.getValue(currentLanguage),
                        classifiersSystemNames: [],
                        timeZoneId: Intl.DateTimeFormat().resolvedOptions()
                            .timeZone,
                        data: data,
                    });
                }),
                first()
            )
            .subscribe(noop, LOGGER.error);

        this.matMenuTrigger.closeMenu();
    }

    public copy(item: FormExportTemplate): void {
        const template$ = item.isPersonalized
            ? this.exportTemplatesService.loadPersonalizedTemplate(item.id)
            : this.exportTemplatesService.loadTemplate(item.id);
        template$
            .pipe(
                switchMap(template =>
                    this.matDialog
                        .open(ExportCopyTemplateDialogComponent, {
                            disableClose: true,
                            autoFocus: true,
                            height: 'auto',
                            width: '450px',
                            data: template.name,
                        })
                        .afterClosed()
                        .pipe(
                            first(),
                            switchMap(
                                (newName: MultilingualString | undefined) => {
                                    if (isNil(newName)) {
                                        return of<PersonalizedTemplate>();
                                    }

                                    return this.exportTemplatesService.createPersonalizedTemplate(
                                        new PersonalizedTemplate(
                                            '',
                                            this.categoryName,
                                            template.htmlTemplate,
                                            newName,
                                            template.description,
                                            -1,
                                            new Date(),
                                            item.isPersonalized
                                                ? (template as PersonalizedTemplate)
                                                      .originalTemplateUUId
                                                : item.id,
                                            Number(
                                                this.form['metaForm'].formId
                                            ),
                                            new Date(),
                                            extensionToExportType(
                                                item.extension
                                            ).valueOf()
                                        )
                                    );
                                }
                            )
                        )
                ),
                take(1),
                tap(() => this.reload$.next(undefined))
            )
            .subscribe(noop, LOGGER.error);
    }

    public remove(item: FormExportTemplate): void {
        this.matDialog
            .open(DeleteConfirmDialogComponent, {
                disableClose: true,
                autoFocus: true,
                height: 'auto',
                width: '450px',
            })
            .afterClosed()
            .pipe(
                first(),
                switchMap(result => {
                    if (result) {
                        return this.exportTemplatesService.deletePersonalizedTemplate(
                            item.id
                        );
                    } else {
                        return of();
                    }
                }),
                take(1),
                tap(() => this.reload$.next(undefined))
            )
            .subscribe(noop, LOGGER.error);
    }

    public setIndex(i: number): void {
        this.selectedIndex = i;
        this.adjustActionIndex(0);
    }

    public handleKeydown(
        keyboardEvent: KeyboardEvent,
        items: FormExportTemplate[]
    ): void {
        let direction: -1 | 0 | 1 = 0;
        switch (keyboardEvent.code) {
            case 'ArrowDown':
                this.selectedIndex =
                    (this.selectedIndex + items.length + 1) % items.length;
                break;
            case 'ArrowUp':
                this.selectedIndex =
                    (this.selectedIndex + items.length - 1) % items.length;
                break;
            case 'ArrowLeft':
                direction = -1;
                break;
            case 'ArrowRight':
                direction = 1;
                break;
            case 'Enter':
                this.actionTypeHandlers[this.actionType].call(
                    this,
                    items[this.selectedIndex]
                );
                break;
            default:
                return;
        }
        this.adjustActionIndex(direction);
    }

    private adjustActionIndex(direction: -1 | 0 | 1): void {
        const activeAction = this.actions.filter(
            x => x.rowIndex === this.selectedIndex
        );
        let currentIndex = this.allActionsTypes.findIndex(
            action => action === this.actionType
        );
        if (currentIndex < 0) {
            throw Error('Illegal state');
        }
        const isActive = actionType =>
            notNil(activeAction.find(action => action.value === actionType));
        if (direction === 0 && isActive(this.allActionsTypes[currentIndex])) {
            return;
        }
        do {
            currentIndex =
                direction !== 0
                    ? (currentIndex + direction + this.allActionsTypes.length) %
                      this.allActionsTypes.length
                    : 0;
        } while (!isActive(this.allActionsTypes[currentIndex]));
        this.actionType = this.allActionsTypes[currentIndex];
    }
}
