import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
} from '@angular/core';
import {
    KbService,
    MetaCategoryId,
    MetaFieldId,
} from '@synisys/idm-kb-service-client-js';
import {MetaCategory} from '@synisys/idm-kb-service-client-js/src/model/meta-category';
import {
    Classifier,
    ClassifierService,
    ClassifiersResponse,
    ClassifierView,
} from '@synisys/idm-classifier-service-client-js';
import {HierarchicalDrillDownItem, HierarchicalDrillDownItems} from './model';
import {Entity} from '@synisys/idm-de-core-frontend';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {AbstractDestructionSubject} from '../../abstract-destruction-subject';
import './hierarchical-drillDown.component.scss';
import {HierarchicalSelectChangedData} from '../../header-three-dot-menu/hierarchical-select-last-level-data.model';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {isNil} from 'lodash';
import {isString} from 'util';
import {notNil} from '@synisys/skynet-store-utilities';
import {createLogger} from '../../utilities';

const logger = createLogger('hierarchical-drillDown');

/**
 * @author tatevik.marikyan
 * @since 29/06/2018
 */

interface LevelChangeData {
    id: number;
    nextItemMetaCategorySystemName: string;
}

@Component({
    moduleId: module.id + '',
    selector: 'hierarchical-drillDown',
    templateUrl: './hierarchical-drillDown.component.html',
})
export class HierarchicalDrillDownComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy, OnChanges {
    @Input()
    public id: string;

    @Input()
    public entity: Entity;

    @Input()
    public validations: Validation[] = [];

    @Input()
    public requiredMetaFields: MetaFieldId[];

    @Input()
    public selectedItemCategories: string[];

    @Input()
    public selectedItemFields: string[];

    @Input()
    public currentLanguageId: number;

    @Input()
    public density: string;

    @Input()
    public isReadonly: boolean;

    @Output()
    public valueChange = new EventEmitter<HierarchicalSelectChangedData>();

    @Output()
    public validationEmitter: EventEmitter<void> = new EventEmitter<void>();

    public hierarchicalItems: HierarchicalDrillDownItems;

    public classifierItems: Map<string, Classifier[]> = new Map<
        string,
        Classifier[]
    >();

    public isReady = false;

    private levelChange$: Subject<any> = new BehaviorSubject(null);

    constructor(
        private classifierService: ClassifierService,
        private kbService: KbService
    ) {
        super();
        this.levelChangeSubscription();
    }

    public ngOnInit(): void {
        this.initHierarchicalComponent();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.levelChange$) {
            this.levelChange$.complete();
        }
    }

    public ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
        if (
            changes['selectedItemCategories'] &&
            changes['selectedItemFields']
        ) {
            const selectedItemCategoriesPrevious =
                changes['selectedItemCategories'].previousValue;
            const selectedItemFieldsPrevious =
                changes['selectedItemFields'].previousValue;

            if (
                selectedItemCategoriesPrevious !== undefined &&
                selectedItemFieldsPrevious !== undefined
            ) {
                this.selectedItemFields =
                    changes['selectedItemFields'].currentValue;
                this.selectedItemCategories =
                    changes['selectedItemCategories'].currentValue;

                this.initHierarchicalComponent();
            }
        }
        if (this.validations && this.validations.length > 0) {
            if (
                this.validations.findIndex((validation: Validation) =>
                    validation
                        .getRelatedMetaFieldIds()
                        .some(
                            (metaFieldId: MetaFieldId) =>
                                this.selectedItemFields.indexOf(
                                    metaFieldId.getSystemName()
                                ) !== -1
                        )
                ) !== -1
            ) {
                this.validationEmitter.emit();
            }
        }
    }

    public translateMultilingual(
        multilingual:
            | MultilingualString
            | {[key: string]: string}
            | string
            | undefined,
        defaultValue: string = ''
    ): string {
        if (isNil(multilingual)) {
            return defaultValue;
        }
        if (isString(multilingual)) {
            return multilingual + '';
        }
        if (isNil(this.currentLanguageId)) {
            logger.error(`Current language is ${this.currentLanguageId}`);
            return defaultValue;
        } else if (multilingual instanceof MultilingualString) {
            return multilingual.getValue(this.currentLanguageId);
        } else if (notNil(multilingual[this.currentLanguageId])) {
            return multilingual[this.currentLanguageId];
        } else {
            return defaultValue;
        }
    }

    public trackByFunc(index: number, item: HierarchicalDrillDownItem) {
        return item && item.value ? item.value.getId() : undefined;
    }

    /**
     * Initializes Hierarchical drillDown component
     */
    private initHierarchicalComponent(): void {
        this.getMetaCategories()
            .pipe(
                mergeMap((metaCategories: MetaCategory[]) => {
                    this.hierarchicalItems = this.initHierarchicalItems(
                        metaCategories
                    );
                    return this.initClassifierItems();
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                (data: ClassifierView[]) => {
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    private getMetaCategories(): Observable<MetaCategory[]> {
        const metaCategoryIds: MetaCategoryId[] = this.selectedItemCategories.map(
            (category: string) => new MetaCategoryId(category)
        );
        const metaCategories$: Observable<MetaCategory>[] = [];

        metaCategoryIds.forEach((metaCategoryId: MetaCategoryId) => {
            metaCategories$.push(
                this.kbService.getMetaCategoryByMetaCategoryId(metaCategoryId)
            );
        });

        return zip(...metaCategories$);
    }

    /**
     * Initializes Hierarchical Items by given metaCategories
     * @param {Array<MetaCategory>} metaCategories
     * @returns {HierarchicalDrillDownItems}
     */
    private initHierarchicalItems(
        metaCategories: MetaCategory[]
    ): HierarchicalDrillDownItems {
        let classifier: Classifier = null;

        const hierarchicalItemsData: HierarchicalDrillDownItem[] = metaCategories.map(
            (metaCategory: MetaCategory, index: number) => {
                classifier = null;

                if (
                    this.entity &&
                    this.entity.getProperty<Classifier>(
                        this.selectedItemFields[index]
                    ).value !== null
                ) {
                    classifier = this.entity.getProperty<Classifier>(
                        this.selectedItemFields[index]
                    ).value;
                }

                const metaFiledId: MetaFieldId = this.requiredMetaFields.find(
                    (metaFieldId: MetaFieldId) =>
                        metaFieldId.getSystemName() ===
                        this.selectedItemFields[index]
                );
                const isRequired: boolean =
                    metaFiledId !== undefined && metaFiledId !== null;

                return new HierarchicalDrillDownItem(
                    classifier,
                    metaCategory,
                    this.selectedItemFields[index],
                    isRequired
                );
            }
        );

        return new HierarchicalDrillDownItems(hierarchicalItemsData);
    }

    /**
     * Initializes Classifier Items map
     * @returns {Observable<Array<ClassifierView>>}
     */
    private initClassifierItems(): Observable<ClassifierView[]> {
        const observables: Observable<any>[] = [];
        let classifiers$: Observable<Classifier[]>;
        let previousHierarchicalItem: HierarchicalDrillDownItem = null;

        this.hierarchicalItems.data.forEach(
            (hierarchicalItem: HierarchicalDrillDownItem, index: number) => {
                if (index === 0) {
                    classifiers$ = this.classifierService
                        .loadAllClassifiers(
                            hierarchicalItem.metaCategory.getSystemName()
                        )
                        .pipe(
                            map((classifiersResponse: ClassifiersResponse) => {
                                if (
                                    classifiersResponse &&
                                    classifiersResponse.getData()
                                ) {
                                    this.classifierItems.set(
                                        hierarchicalItem.metaCategory.getSystemName(),
                                        classifiersResponse.getData()
                                    );
                                    return classifiersResponse.getData();
                                }
                                return [];
                            })
                        );
                } else if (previousHierarchicalItem.value === null) {
                    this.classifierItems.set(
                        hierarchicalItem.metaCategory.getSystemName(),
                        []
                    );
                    classifiers$ = of([]);
                } else {
                    classifiers$ = this.classifierService
                        .loadClassifiersByParent(
                            hierarchicalItem.metaCategory.getSystemName(),
                            previousHierarchicalItem.value.getId()
                        )
                        .pipe(
                            map((classifiersResponse: ClassifiersResponse) => {
                                if (
                                    classifiersResponse &&
                                    classifiersResponse.getData()
                                ) {
                                    this.classifierItems.set(
                                        hierarchicalItem.metaCategory.getSystemName(),
                                        classifiersResponse.getData()
                                    );
                                    return classifiersResponse.getData();
                                }
                                return [];
                            })
                        );
                }

                previousHierarchicalItem = hierarchicalItem;
                observables.push(classifiers$);
            }
        );
        return zip(...observables);
    }

    private levelChangeSubscription(): void {
        let data: LevelChangeData;
        this.levelChange$
            .pipe(
                mergeMap((levelChangeData: LevelChangeData) => {
                    if (levelChangeData !== null) {
                        data = levelChangeData;
                        if (data.id !== null && data.id !== undefined) {
                            return this.classifierService.loadClassifiersByParent(
                                data.nextItemMetaCategorySystemName,
                                data.id
                            );
                        }
                    }
                    return of(null);
                })
            )
            .subscribe(
                (classifiersResponse: ClassifiersResponse) => {
                    if (
                        data &&
                        data.nextItemMetaCategorySystemName &&
                        classifiersResponse
                    ) {
                        this.classifierItems.set(
                            data.nextItemMetaCategorySystemName,
                            classifiersResponse.getData()
                        );
                    }
                },
                err => console.error(err)
            );
    }

    /**
     * On Hierarchical component Level change action
     * @param {number} selectedValueId
     * @param {HierarchicalDrillDownItem} currentHierarchicalItem
     */
    public onLevelChange(
        selectedValueId: number,
        currentHierarchicalItem: HierarchicalDrillDownItem
    ): void {
        let selectedValue: Classifier = null;

        if (
            this.entity &&
            this.isClassifierChanged(selectedValueId, currentHierarchicalItem)
        ) {
            this.onValueChangeEmit(selectedValueId, currentHierarchicalItem);

            if (selectedValueId !== null) {
                selectedValue = this.classifierItems
                    .get(currentHierarchicalItem.metaCategory.getSystemName())
                    .find(
                        (classifier: Classifier) =>
                            classifier.getId() === selectedValueId
                    );
            }

            this.entity.getProperty<Classifier>(
                currentHierarchicalItem.metaFieldName
            ).value = selectedValue;
            currentHierarchicalItem.value = selectedValue;

            const nextHierarchicalItem: HierarchicalDrillDownItem = this.hierarchicalItems.getNextItem(
                currentHierarchicalItem
            );
            //reset child classifiers values
            if (nextHierarchicalItem !== undefined) {
                this.resetChildClassifiers(nextHierarchicalItem);
            }
            //Load next classifier items
            if (
                nextHierarchicalItem !== undefined &&
                nextHierarchicalItem !== null
            ) {
                let data: LevelChangeData = {
                    id: selectedValueId,
                    nextItemMetaCategorySystemName: nextHierarchicalItem.metaCategory.getSystemName(),
                };
                this.levelChange$.next(data);
            }
        }
    }

    private onValueChangeEmit(
        selectedValueId: number,
        currentHierarchicalItem: HierarchicalDrillDownItem
    ): void {
        let hierarchicalItem: HierarchicalDrillDownItem = currentHierarchicalItem;

        if (!selectedValueId) {
            hierarchicalItem = this.hierarchicalItems.getPreviousItem(
                currentHierarchicalItem
            );
        }

        let lastLevelClassifierData: HierarchicalSelectChangedData = null;
        if (hierarchicalItem) {
            lastLevelClassifierData = {
                categoryName: hierarchicalItem.metaCategory.getSystemName(),
                metaFieldName: hierarchicalItem.metaFieldName,
                value: selectedValueId
                    ? selectedValueId
                    : hierarchicalItem.value.getId(),
            };
        }

        this.valueChange.emit(lastLevelClassifierData);
    }

    private isClassifierChanged(
        selectedValueId: number,
        currentHierarchicalItem: HierarchicalDrillDownItem
    ) {
        const classifier = this.entity.getProperty<ClassifierView>(
            currentHierarchicalItem.metaFieldName
        ).value;
        if (classifier === null && selectedValueId === null) {
            return false;
        } else if (classifier && classifier.getId() === selectedValueId) {
            return false;
        } else {
            return true;
        }
    }

    public resetChildClassifiers(hierarchicalItem: HierarchicalDrillDownItem) {
        this.entity.getProperty<ClassifierView>(
            hierarchicalItem.metaFieldName
        ).value = null;
        hierarchicalItem.value = null;
        this.classifierItems.set(
            hierarchicalItem.metaCategory.getSystemName(),
            []
        );

        const nextHierarchicalItem: HierarchicalDrillDownItem = this.hierarchicalItems.getNextItem(
            hierarchicalItem
        );
        if (nextHierarchicalItem) {
            this.resetChildClassifiers(nextHierarchicalItem);
        }
    }

    public getFxFlex(): number {
        switch (this.density) {
            case 'loose': {
                return 50;
            }
            case 'default': {
                return 33;
            }
            case 'compact': {
                return 25;
            }
            default: {
                return 100;
            }
        }
    }
}
