/**
 * @author tatevik.marikyan
 * @since 09/07/2018
 */

import {first, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {
    KbService,
    MetaCategoryId,
    MetaField,
    MetaFieldId,
    MetaFieldType,
    MetaGroup,
} from '@synisys/idm-kb-service-client-js';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {CompatibleMetaField, HierarchicalSelectSettings} from './model';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {MetaCategory} from '@synisys/idm-kb-service-client-js/src/model/meta-category';
import {multilingualToString, stringToMultilingual} from '../../utilities';
import {AbstractDestructionSubject} from '../../abstract-destruction-subject';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {zip} from 'rxjs/observable/zip';
import {of} from 'rxjs/observable/of';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import './hierarchical-select-settings.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'hierarchical-select-settings',
    templateUrl: 'hierarchical-select-settings.component.html',
})
@ControlMetadata({
    template: `<hierarchical-select-settings
                [category]="%{category}"
                [state]="%{state}">
             </hierarchical-select-settings>`,
})
export class HierarchicalSelectSettingsComponent
    extends AbstractDestructionSubject
    implements OnInit, OnDestroy {
    @Input()
    public category: string;

    @Input()
    public state: any;

    public hierarchicalSelectSetting: HierarchicalSelectSettings;

    public selectedMetaField: MetaField = undefined;

    public lastLevelCompatibleMetaFields: CompatibleMetaField[] = [];

    public selectableMetaFieldIndex = 1;

    public isVertical = true;

    public isReady = false;

    public densityValues: any[] = [
        {name: 'Loose', id: 'loose'},
        {name: 'Default', id: 'default'},
        {name: 'Compact', id: 'compact'},
    ];
    public controlDesignValues: any[] = [
        {name: 'Search', id: 'search'},
        {name: 'Drilldown', id: 'drillDown'},
        {name: 'Search & Drilldown', id: 'search-drillDown'},
    ];

    public languages: Language[];

    public currentLanguageId: number = null;

    private levelChange$: Subject<any> = new BehaviorSubject(null);

    private readonly defaultMetaGroup: MetaGroup = new MetaGroup(
        null,
        'de.default.meta.group',
        []
    );

    constructor(
        private kbService: KbService,
        private languageService: LanguageService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private communicationService: CommunicationService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.levelChange$) {
            this.levelChange$.unsubscribe();
        }
    }

    public ngOnInit(): void {
        const languages$ = this.languageService.getInputLanguages();
        const currentLanguageId$ = this.currentLanguageProvider.getCurrentLanguage();
        const selectedMetaFields$ = this.getSelectedMetaFields();

        combineLatest(languages$, currentLanguageId$, selectedMetaFields$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (data: any[]) => {
                    this.languages = data[0];
                    this.currentLanguageId = data[1].getId();
                    const selectedMetaFields: MetaField[] = data[2];
                    this.hierarchicalSelectSetting = this.getHierarchicalSelectSetting(
                        selectedMetaFields
                    );
                    this.isReady = true;
                },
                err => console.error(err)
            );
        this.levelChangeSubscription();
    }

    public getMultilingualTitle(
        title: MultilingualString | string
    ): MultilingualString {
        if (title) {
            if (!(title instanceof MultilingualString) && title !== '') {
                return stringToMultilingual(title);
            } else {
                return <MultilingualString>title;
            }
        } else {
            return MultilingualString.newBuilder()
                .withValueForLanguages(
                    this.languages.map(language => language.getId()),
                    ''
                )
                .build();
        }
    }

    public refreshCommunicationService(): void {
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                metaField: undefined,
                bindings: this.getBindings(),
                state: this.getState(),
            })
        );
    }

    public addLevel(): void {
        this.hierarchicalSelectSetting.selectedMetaFields.push(
            this.selectedMetaField
        );
        this.selectedMetaField = undefined;
        this.selectableMetaFieldIndex =
            this.hierarchicalSelectSetting.selectedMetaFields.length + 1;

        this.levelChange$.next({
            selectedMetaField: this.selectedMetaField,
        });
    }

    public deleteLastLevel(): void {
        const lastIndex =
            this.hierarchicalSelectSetting.selectedMetaFields.length - 1;
        this.selectedMetaField = this.hierarchicalSelectSetting.selectedMetaFields[
            lastIndex
        ];

        this.hierarchicalSelectSetting.selectedMetaFields.splice(-1);

        this.selectableMetaFieldIndex =
            this.hierarchicalSelectSetting.selectedMetaFields.length + 1;

        this.levelChange$.next({
            selectedMetaField: this.selectedMetaField,
        });
    }

    public changeOrientationValue(value: boolean): void {
        this.isVertical = value;
    }

    public trackByFunc(index: number, selectedMetaField: MetaField) {
        return selectedMetaField.getMetaFieldId();
    }

    private getSelectedMetaFields(): Observable<MetaField[]> {
        const entityCategorySystemName = this.state.entityCategorySystemName;

        let selectedItemFields = [];
        if (this.state.selectedItemFields !== '') {
            selectedItemFields =
                typeof this.state.selectedItemFields === 'string'
                    ? JSON.parse(this.state.selectedItemFields)
                    : this.state.selectedItemFields;
        }

        const selectedMetaFields: Observable<
            MetaField
        >[] = selectedItemFields.map((fieldSystemName: string) => {
            const mockedMetaFieldId = new MetaFieldId(
                new MetaCategoryId(entityCategorySystemName),
                fieldSystemName
            );
            return this.kbService.getMetaFieldByMetaFieldId(mockedMetaFieldId);
        });

        return selectedMetaFields.length > 0
            ? zip(...selectedMetaFields)
            : of([]);
    }

    private getHierarchicalSelectSetting(
        selectedMetaFields: MetaField[]
    ): HierarchicalSelectSettings {
        const title: MultilingualString = this.getMultilingualTitle(
            this.state.title
        );
        this.isVertical = !this.state.density;
        const density: string = this.state.density
            ? this.state.density
            : 'loose';
        const controlDesignValue = this.state.switchModes
            ? this.state.switchModes.join('-')
            : '';

        this.selectedMetaField = undefined;
        this.selectableMetaFieldIndex = selectedMetaFields.length + 1;

        return new HierarchicalSelectSettings(
            title,
            selectedMetaFields,
            density,
            controlDesignValue
        );
    }

    private levelInitialization(): Observable<void> {
        return this.kbService.getNonSystemMetaFields(this.category).pipe(
            first(),
            mergeMap((metaFields: MetaField[]) =>
                zip(
                    of(metaFields),
                    this.kbService.getNonSystemMetaGroups(this.category),
                    zip(
                        ...metaFields.map((metaField: MetaField) =>
                            this.isFieldCompatible(metaField)
                        )
                    )
                )
            ),
            map(([metaFields, metaGroups, compatibilityArray]) => {
                this.lastLevelCompatibleMetaFields = metaFields
                    .filter(
                        (metaField: MetaField, index: number) =>
                            compatibilityArray[index]
                    )
                    .map((metaField: MetaField) => {
                        const metaGroup: MetaGroup = metaGroups.find(
                            (group: MetaGroup) =>
                                group.getSystemName() ===
                                metaField
                                    .getMetaFieldId()
                                    .getMetaGroupId()
                                    .getSystemName()
                        );
                        return {
                            metaField,
                            metaGroup: metaGroup || this.defaultMetaGroup,
                        } as CompatibleMetaField;
                    });
            })
        );
    }

    /**
     * Child or Next Level Initialization
     */
    private nextLevelInitialization(): void {
        const categorySystemName = this.selectedMetaField.getCompoundCategorySystemName();

        this.kbService
            .getClassifierMetaCategories()
            .pipe(
                mergeMap((metaCategories: Set<MetaCategory>) => {
                    const metaCategoriesArray: MetaCategory[] = Array.from(
                        metaCategories.values()
                    );
                    const metaCategorySystemNames = metaCategoriesArray.map(
                        (metaCategory: MetaCategory) =>
                            metaCategory.getSystemName()
                    );
                    const metaCategoryMetaFields$: Observable<
                        MetaField[]
                    >[] = [];

                    metaCategorySystemNames.forEach((systemName: string) => {
                        metaCategoryMetaFields$.push(
                            this.kbService.getMetaFields(systemName)
                        );
                    });
                    return zip(...metaCategoryMetaFields$);
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                (metaCategoryMetaFields: MetaField[][]) => {
                    metaCategoryMetaFields.forEach(
                        (metaFields: MetaField[]) => {
                            const parentCategoryMetaField: MetaField = metaFields.find(
                                (metaField: MetaField) =>
                                    metaField.getType() ===
                                        MetaFieldType.PARENT &&
                                    metaField.getSystemName() ===
                                        categorySystemName
                            );
                        }
                    );
                },
                error1 => console.error(error1)
            );
    }

    private isFieldCompatible(metaField: MetaField): Observable<boolean> {
        if (metaField.getType() !== MetaFieldType.CLASSIFIER) {
            return of(false);
        }
        if (
            !this.hierarchicalSelectSetting.selectedMetaFields ||
            this.hierarchicalSelectSetting.selectedMetaFields.length === 0
        ) {
            return of(true);
        }
        return this.kbService
            .getMetaFields(metaField.getCompoundCategorySystemName())
            .pipe(
                map((classifierMetaFields: MetaField[]) => {
                    const parentMetaField: MetaField = classifierMetaFields.find(
                        (classifierMetaField: MetaField) =>
                            classifierMetaField.getType() ===
                            MetaFieldType.PARENT
                    );
                    return !!(
                        parentMetaField &&
                        parentMetaField.getCompoundCategorySystemName() ===
                            this.hierarchicalSelectSetting.selectedMetaFields[
                                this.hierarchicalSelectSetting
                                    .selectedMetaFields.length - 1
                            ].getCompoundCategorySystemName()
                    );
                })
            );
    }

    private levelChangeSubscription(): void {
        let changedData: {selectedMetaField: MetaField};
        this.levelChange$
            .pipe(
                mergeMap((levelChangeData: {selectedMetaField: MetaField}) => {
                    changedData = levelChangeData;
                    return this.levelInitialization();
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                () => {
                    this.isReady = true;
                    if (changedData !== null) {
                        this.communicationService.parentSubject.next(
                            new CommunicationData(
                                CommunicationKey.SETTINGS_CHANGED,
                                {
                                    metaField: null,
                                    bindings: this.getBindings(),
                                    state: this.getState(),
                                    fields: this.hierarchicalSelectSetting
                                        .selectedMetaFields,
                                }
                            )
                        );
                    }
                },
                err => console.error(err)
            );
    }

    private getBindings(): object {
        return {};
    }

    private getState(): object {
        return {
            title: multilingualToString(this.hierarchicalSelectSetting.title),
            density: !this.isVertical
                ? this.hierarchicalSelectSetting.density
                : undefined,
            entityCategorySystemName: this.category,
            selectedItemCategories: this.hierarchicalSelectSetting.selectedMetaFields.map(
                (metaField: MetaField) =>
                    metaField.getCompoundCategorySystemName()
            ),
            selectedItemFields: this.hierarchicalSelectSetting.selectedMetaFields.map(
                (metaField: MetaField) => metaField.getSystemName()
            ),
            switchModes: this.hierarchicalSelectSetting.controlDesign.split(
                '-'
            ),
        };
    }
}
