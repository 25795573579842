import {Directive, Input} from '@angular/core';

@Directive({
    selector: '[sisHelper]',
})
export class HelperDirective {
    @Input('sisHelper')
    public value: string | number;
    @Input()
    public rowIndex: number;
}
