import {HierarchicalEntityItem} from './hierarchical-entity-item';
import {Map} from 'immutable';

/**
 * @author tatevik.marikyan
 * @since 29/07/2018
 */

export class HierarchicalSearchItem {

  /**
   * Model for each searched result item in Hierarchical Search component
   * @param {string} displayName
   * @param {Map<string, number>} fieldValueMapping - Hierarchical items Meta Filed Name / value (ClassifierId) mapping
   * @param {HierarchicalEntityItem} entityItem
   */
  constructor(public displayName: string,
              public fieldValueMapping: Map<string, number>,
              public entityItem?: HierarchicalEntityItem) {
  }
}
