import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {KbService, MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {
  CommunicationData,
  CommunicationKey,
  CommunicationService,
  ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {fullParse} from '../utilities';
import {takeUntil} from 'rxjs/operators';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';

@Component({
             moduleId   : module.id + '',
             selector   : 'entity-field-viewer-settings',
             templateUrl: 'entity-field-viewer-settings.component.html',
           })
@ControlMetadata({
                   template: `
                      <entity-field-viewer-settings [category]="%{category}" [fields]="%{fields}" [state]="%{state}">
                      </entity-field-viewer-settings>`,
                 })
export class EntityFieldViewerSettingsComponent extends AbstractDestructionSubject implements OnInit, OnDestroy {

  private static isAvailable(metaField: MetaField): boolean {
    switch (metaField.getType()) {
      case MetaFieldType.INTEGER:
      case MetaFieldType.DECIMAL:
      case MetaFieldType.STRING:
      case MetaFieldType.MULTILINGUAL_STRING:
      case MetaFieldType.CLASSIFIER:
      case MetaFieldType.MULTI_SELECT:
      case MetaFieldType.DATE:
      case MetaFieldType.LOCAL_DATE:
      case MetaFieldType.DATE_TIME:
        return true;
      default:
        return false;
    }
  }

  @Input()
  public category: string;
  @Input()
  public fields: object;
  @Input()
  public state: object;

  public compatibleMetaFields: string[] = [];
  public compatibleMetaFieldsMsg: string[] = [];

  public isReady = false;

  private selectedMetaField: string = null;

  constructor(private kbService: KbService,
              private communicationService: CommunicationService) {
    super();
  }

  public valueChange(): void {
    this.communicationService.parentSubject.next(new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
      bindings: this.getBindings(),
      fields  : this.fields,
      state   : {
        viewFieldSystemName: this.selectedMetaField,
      },
    }));
  }

  public ngOnInit(): void {
    const fields$ = this.kbService.getMainEntityMetaFields(this.category, this.fields['field'].metaFieldId.systemName);
    const viewFieldSystemName = fullParse(this.state['viewFieldSystemName']);
    if (viewFieldSystemName) {
      this.selectedMetaField = viewFieldSystemName;
    }
    fields$.pipe(takeUntil(this.destroySubject$))
           .subscribe((metaFields: MetaField[]) => {
             this.compatibleMetaFields =
               metaFields.filter(EntityFieldViewerSettingsComponent.isAvailable).map((metaField: MetaField) => {
                 this.compatibleMetaFieldsMsg.push(metaField.getDisplayNameMsgId());
                 return metaField.getSystemName();
               });
             this.isReady = true;
           }, err => console.error(err));
  }

  private getBindings(): object {
    return {
      value     : `entity.getProperty('${this.fields['field'].metaFieldId.systemName}').value`,
      categoryId: this.category,
    };
  }

}
