import {Component, Input, OnInit} from '@angular/core';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {
    CategoryTemplateViewModel,
    ExportTemplatesService,
} from '@synisys/idm-export-templates-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {switchMap, tap} from 'rxjs/operators';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {GroupData, ListItem} from '../chip-group';
import {noop} from 'rxjs/util/noop';

export type Extension = 'PDF' | 'RTF' | 'XLS';

export interface ExportTemplate {
    id: string;
    name?: string;
    extension: Extension;
}

@Component({
    moduleId: module.id + '',
    selector: 'sis-header-settings',
    templateUrl: 'header-settings.component.html',
})
@ControlMetadata({
    template: `
        <sis-header-settings [state]="%{state}" [category]="%{category}">
        </sis-header-settings>
    `,
})
export class HeaderSettingsComponent implements OnInit {
    public readonly extensions: Extension[] = ['PDF', 'RTF', 'XLS'];
    public isReady = false;

    private _category: string;
    private _selectedTemplates: ExportTemplate[] = [];
    private _exportTemplatesData: GroupData;

    get category(): string {
        return this._category;
    }

    @Input()
    set category(value: string) {
        this._category = value;
    }

    get selectedTemplates(): ExportTemplate[] {
        return this._selectedTemplates;
    }

    set selectedTemplates(value: ExportTemplate[]) {
        this._selectedTemplates = value;
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    exportTemplates: this.selectedTemplates,
                },
            })
        );
    }

    get exportTemplatesData(): GroupData {
        return this._exportTemplatesData;
    }

    @Input()
    set state(value: object) {
        if (value['exportTemplates']) {
            this._selectedTemplates = value['exportTemplates'];
        }
    }

    constructor(
        private communicationService: CommunicationService,
        private exportTemplatesService: ExportTemplatesService,
        private currentLanguageProvider: CurrentLanguageProvider
    ) {}

    public ngOnInit(): void {
        this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(
                switchMap((currentLanguage: Language) =>
                    this.exportTemplatesService.loadTemplatesDataByCategoryName(
                        this.category,
                        currentLanguage.getId()
                    )
                ),
                tap((templates: CategoryTemplateViewModel[]) => {
                    const viewExportTemplates = templates.map<ExportTemplate>(
                        template => ({
                            id: template.id,
                            name: template.name,
                            extension: 'PDF',
                        })
                    );
                    this._exportTemplatesData = {
                        items: viewExportTemplates,
                        name: '',
                    };
                    this.isReady = true;
                })
            )
            .subscribe(noop, console.log);
    }

    // tslint:disable-next-line:no-any
    public onSelectedTemplatesChange(selectedItems: ListItem[]): void {
        this.selectedTemplates = selectedItems.map((item: ListItem) => ({
            id: item.id.toString(),
            name: item.name,
            extension: item.comboItem,
        }));
    }
}
