/**
 * @author tatevik.marikyan
 * @since 03/07/2018
 */
import {ClassifierView} from "@synisys/idm-classifier-service-client-js";
import {HierarchicalDrillDownItem} from "./hierarchical-drillDown-item.model";

export class HierarchicalDrillDownItems {

  /**
   * @param {Array<ClassifierView>} data
   */
  constructor(public data: Array<HierarchicalDrillDownItem>) {
  }

  public getFirstItem(): HierarchicalDrillDownItem {
    return this.data !== undefined && this.data !== null && this.data.length > 0 ? this.data[0] : null;
  }

  public getNextItem(currentHierarchicalItem: HierarchicalDrillDownItem): HierarchicalDrillDownItem {
    let index = this.data.indexOf(currentHierarchicalItem);
    return index !== null && index >= 0 ? this.data[index + 1] : null;
  }

  public getPreviousItem(currentHierarchicalItem: HierarchicalDrillDownItem): HierarchicalDrillDownItem {
    let index = this.data.indexOf(currentHierarchicalItem);
    return index !== null && index >= 0 ? this.data[index - 1] : null;
  }

}
