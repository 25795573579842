import {empty} from 'rxjs/observable/empty';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    DePermission,
    DePermissionService,
    DeService,
} from '@synisys/idm-de-service-client-js';
import {MatDialog} from '@angular/material';
import {DeleteConfirmDialogComponent} from '../delete-confirm-dialog';
import {Router} from '@angular/router';
import './header-three-dot-menu.component.scss';
import {
    catchError,
    first,
    mergeMap,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {
    Access,
    AuthorizationService,
    PermissionValueDto,
} from '@synisys/idm-authorization-client-js';
import {
    AuthenticationService,
    CookieService,
    HttpClientWrapper,
    UserData,
} from '@synisys/idm-authentication-client-js';
import {noop} from 'rxjs/util/noop';
import {Observable} from 'rxjs/Observable';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {zip} from 'rxjs/observable/zip';
import {UserHelpItem} from '../../models/user-help-item.model';
import {
    DocumentInfo,
    DocumentService,
    FileType,
} from '@synisys/idm-document-service-client-js';
import {from} from 'rxjs/observable/from';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';

/**
 * @author tatevik.marikyan
 * @since 26/06/2018
 */
@Component({
    moduleId: module.id + '',
    selector: 'header-three-dot-menu',
    templateUrl: './header-three-dot-menu.component.html',
})
export class HeaderThreeDotMenuComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy {
    @Input()
    public id: string;
    @Input()
    public classes = '';
    @Input()
    public systemName: string;
    @Input()
    public entityId: number;
    @Input()
    public entityInstanceId: number;
    @Input()
    public withDeleteButton = true;
    public currentLanguage$: Observable<Language>;
    private _userHelpItems: UserHelpItem[];
    private _canDelete = false;
    private _isView = true;
    private _hasChangeHistoryPermission = false;

    public get userHelpItems(): UserHelpItem[] {
        return this._userHelpItems;
    }

    @Input()
    public set userHelpItems(value: UserHelpItem[]) {
        this._userHelpItems = value
            ? value.filter(
                  (userHelpItem: UserHelpItem) =>
                      !!userHelpItem.url ||
                      (userHelpItem.documentId &&
                          userHelpItem.documentId !== -1)
              )
            : [];
    }

    private readonly GLOBAL_AUDIT_PERMISSION_KEY: string =
        '97f2eb0b-9c8e-4f76-8ce7-a725e31afd84';

    constructor(
        private _deService: DeService,
        private _dePermissionService: DePermissionService,
        private _authorizationService: AuthorizationService,
        private _authenticationService: AuthenticationService,
        private _currentLanguageProvider: CurrentLanguageProvider,
        private _documentService: DocumentService,
        private _applicationPropertiesService: ApplicationPropertiesService,
        private _formDialog: MatDialog,
        private _httpClient: HttpClientWrapper,
        private _cookieService: CookieService,
        private _router: Router
    ) {
        super();
    }

    get isView(): boolean {
        return this._isView;
    }

    @Input()
    set isView(value: boolean) {
        this._isView = value;
    }

    get canDelete(): boolean {
        return this._canDelete;
    }

    get hasChangeHistoryPermission(): boolean {
        return this._hasChangeHistoryPermission;
    }

    public ngOnInit(): void {
        this.deletePermissionInit();
        this.hasChangeHistoryPermissionInit();
        this.currentLanguage$ = this._currentLanguageProvider.getCurrentLanguage();
    }

    public deleteAction(): void {
        this._formDialog
            .open(DeleteConfirmDialogComponent, {
                disableClose: true,
                autoFocus: true,
                height: 'auto',
                width: '450px',
            })
            .afterClosed()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((result: boolean) => {
                if (result) {
                    this.deleteEntity();
                }
            }, console.error);
    }

    public onChangeHistoryClick(): void {
        this._router
            .navigateByUrl(`audit/${this.systemName}/${this.entityInstanceId}`)
            .then(noop);
    }

    public onUserHelpItemClick(userHelpItem: UserHelpItem): void {
        if (userHelpItem.documentId && userHelpItem.documentId !== -1) {
            this._deService
                .getDocumentTokenByDocumentId(
                    this.systemName,
                    userHelpItem.documentId
                )
                .pipe(
                    first(),
                    switchMap((authInfo: string) =>
                        zip(
                            this._documentService.getDownloadUrl(
                                userHelpItem.documentId,
                                authInfo
                            ),
                            this._documentService.getDocumentInfo(
                                userHelpItem.documentId
                            )
                        )
                    ),
                    tap((urlAndInfo: [string, DocumentInfo]) => {
                        if (urlAndInfo[1].name.toLowerCase().endsWith('pdf')) {
                            this.openPdfInNewTab(urlAndInfo[0]);
                        } else {
                            this._documentService.downloadDocument(
                                urlAndInfo[0],
                                FileType.ORIGINAL
                            );
                        }
                    })
                )
                .subscribe(noop, console.error);
        } else {
            window.open(userHelpItem.url);
        }
    }

    // TODO: Remove this function when a similar functionality is available in document service
    private openPdfInNewTab(documentUrl: string): void {
        zip(
            from(
                this._applicationPropertiesService.getProperty(
                    'document-service-url'
                )
            ),
            this._authenticationService.getUserData()
        )
            .pipe(
                first(),
                mergeMap((data: [string, UserData]) =>
                    this._httpClient.get(
                        `${data[0]}/document/${documentUrl}?fileType=${FileType.ORIGINAL}&userID=${data[1].userId}`,
                        {responseType: 'arraybuffer'}
                    )
                ),
                tap((response: ArrayBuffer) =>
                    window.open(
                        URL.createObjectURL(
                            new Blob([response], {type: 'application/pdf'})
                        )
                    )
                )
            )
            .subscribe(noop, console.error);
    }

    /**
     * Initializes Delete permission by checking Categories De Permissions for given entity
     */
    private deletePermissionInit(): void {
        if (this.entityInstanceId && this.withDeleteButton) {
            this._dePermissionService
                .getCategoryDePermissionsByInstance(
                    this.systemName,
                    this.entityInstanceId
                )
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((dePermission: DePermission) => {
                    this._canDelete = dePermission.canDelete;
                }, console.error);
        }
    }

    private hasChangeHistoryPermissionInit(): void {
        this._authenticationService
            .getUserData()
            .pipe(
                mergeMap((userData: UserData) => {
                    return this._authorizationService.getPermissionAccessValue(
                        userData.userId,
                        this.GLOBAL_AUDIT_PERMISSION_KEY
                    );
                }),
                tap((permissionValue: PermissionValueDto) => {
                    this._hasChangeHistoryPermission =
                        permissionValue.access !== Access.DENIED;
                })
            )
            .subscribe(noop, console.log);
    }

    /**
     * Deletes Entity by double checking Delete Permission
     * Navigates to following pages if below result appears:
     * 1. no delete permission  - no-permission/delete
     * 2. delete                - successfully-deleted
     * 3. no item for delete    - deleted
     */
    private deleteEntity(): void {
        this._dePermissionService
            .getCategoryDePermissionsByInstance(
                this.systemName,
                this.entityInstanceId
            )
            .pipe(
                switchMap((dePermission: DePermission) => {
                    if (dePermission.canDelete) {
                        return this._deService
                            .deleteById(this.systemName, this.entityId)
                            .pipe(
                                tap(() =>
                                    this._router.navigateByUrl(
                                        'dynamic-de/successfully-deleted'
                                    )
                                ),
                                catchError(err => {
                                    if (err.status === 404) {
                                        this._router
                                            .navigateByUrl('dynamic-de/deleted')
                                            .then(noop);
                                    }
                                    return empty();
                                })
                            );
                    } else {
                        return this._router.navigateByUrl(
                            'dynamic-de/no-permission/delete'
                        );
                    }
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }
}
