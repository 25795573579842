import {debounceTime, map, mergeMap, takeUntil} from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material';
import {
    Classifier,
    ClassifierService,
} from '@synisys/idm-classifier-service-client-js';
import {Entity, PagingOptions} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaCategoryId,
    MetaFieldId,
} from '@synisys/idm-kb-service-client-js';
import {MetaField} from '@synisys/idm-kb-service-client-js/src/model/meta-field';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {Observable} from 'rxjs/Observable';
import {HierarchicalSearchFilterOptions, HierarchicalSearchItem} from './model';
import {HierarchicalSearchService} from './services';
import {Map} from 'immutable';
import './hierarchical-search.component.scss';
import {zip} from 'rxjs/observable/zip';
import {AbstractDestructionSubject} from '../../abstract-destruction-subject';
import {of} from 'rxjs/observable/of';
import {HierarchicalSelectChangedData} from '../../header-three-dot-menu/hierarchical-select-last-level-data.model';
import {
    ServiceResponse,
    ServiceResponseDefault,
} from '@synisys/idm-de-service-client-js';

/**
 * @author tatevik.marikyan
 * @since 29/06/2018
 */

@Component({
    moduleId: module.id + '',
    selector: 'hierarchical-search',
    templateUrl: './hierarchical-search.component.html',
})
export class HierarchicalSearchComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy {
    @Input()
    public id: string;

    @Input()
    public currentLanguageId: number;

    @Input()
    public entity: Entity;

    @Input()
    public validations: Validation[] = [];

    @Input()
    public entityCategorySystemName: string;

    @Input()
    public selectedItemCategories: string[];

    @Input()
    public selectedItemFields: string[];

    @Input()
    public isReadonly: boolean;

    @Output()
    public valueChange = new EventEmitter<HierarchicalSelectChangedData>();

    @Output()
    public validationEmitter: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Hierarchical items Category Name / Meta Filed Name mapping
     */
    @Input()
    public categoryFieldsMapping: Map<string, string>;

    @ViewChild('autoCompleteInput', {read: MatAutocompleteTrigger})
    public autoComplete: MatAutocompleteTrigger;

    public searchCtrl: FormControl = new FormControl();

    /**
     * Filtered Data's total rows count
     */
    public totalRows: number;

    /**
     * Rows to show after search.
     */
    public rowsToShow: number;

    /**
     * Selected Entity.
     */
    public selectedEntity: HierarchicalSearchItem;

    public filteredOptions: Observable<
        HierarchicalSearchItem[] | Observable<any>
    >;

    private DEFAULT_ROWS_COUNT = 5;

    /**
     * Filtered Hierarchical search items' data
     * @type {any[]}
     * @private
     */
    private filteredData: HierarchicalSearchItem[] = [];

    /**
     * Load More Hierarchical Search Item
     * @type {HierarchicalSearchItem}
     * @private
     */
    private loadMoreEntity: HierarchicalSearchItem = new HierarchicalSearchItem(
        'loadMore',
        Map<string, number>()
    );

    /**
     * property for storing Hierarchical Component's Root Category name (for specified hierarchical group)
     */
    private rootCategoryName: string;

    constructor(
        private classifierService: ClassifierService,
        private kbService: KbService,
        private messageService: MessageService,
        private searchService: HierarchicalSearchService
    ) {
        super();
        this.onFilterValueChange();
        this.rowsToShow = this.DEFAULT_ROWS_COUNT;
    }

    public ngOnInit() {
        this.initHierarchicalSearchComponent();
    }

    public onEntitySelected(event: any): void {
        if (event.source.selected) {
            this.selectedEntity = event.source.value;
            this.searchCtrl.setValue(event.source.value.displayName);
            this.rowsToShow = this.DEFAULT_ROWS_COUNT;

            this.bindSelectedValueToEntity(this.selectedEntity);
        }
    }

    public onLoadMoreSelected(event: any): void {
        if (event.source.selected) {
            this.rowsToShow += this.DEFAULT_ROWS_COUNT;

            const displayName =
                this.searchCtrl.value instanceof HierarchicalSearchItem
                    ? this.searchCtrl.value.displayName
                    : this.searchCtrl.value;
            this.loadMoreEntity.displayName = displayName;
            this.searchCtrl.setValue(displayName, {emitEvent: true});
        }
    }

    /**
     * Indicates when to show "Load more" option
     * @returns {boolean}
     */
    public showLoadMoreOption(): boolean {
        return this.totalRows > this.rowsToShow;
    }

    /**
     * Indicates when to show "No More available items"
     * @returns {boolean}
     */
    public showNoDataAvailable(): boolean {
        return (
            !this.isEmptyValue() &&
            !this.selectedEntity &&
            this.filteredData.length === 0
        );
    }

    public isEmptyValue(): boolean {
        return (
            this.searchCtrl.value === undefined ||
            this.searchCtrl.value === null ||
            this.searchCtrl.value === ''
        );
    }

    public clearSearchCtrl(): void {
        this.selectedEntity = null;
        this.rowsToShow = this.DEFAULT_ROWS_COUNT;
        this.totalRows = 0;
        this.filteredData = [];
        this.searchCtrl.setValue('');
        this.valueChange.emit(null);

        this.selectedItemFields.forEach((itemField: string) => {
            this.entity.getProperty<Classifier>(itemField).value = null;
        });
    }

    public resetRowCount(): void {
        if (this.searchCtrl.value === '') {
            this.rowsToShow = this.DEFAULT_ROWS_COUNT;
        }
    }

    public getValidationMsg(): Observable<string> {
        if (!this.validations || this.validations.length === 0) {
            return of('');
        }
        const validationRelatedMetaFieldNames: string[] = this.validations
            .map((validation: Validation) =>
                validation
                    .getRelatedMetaFieldIds()
                    .map((relatedMetaFieldId: MetaFieldId) =>
                        relatedMetaFieldId.getSystemName()
                    )
            )
            .reduce((result: string[], relatedMetaFieldNames: string[]) =>
                result.concat(relatedMetaFieldNames)
            );
        const validationMetaFieldNames: string[] = this.selectedItemFields
            .map((metaFieldName: string) =>
                validationRelatedMetaFieldNames.find(
                    (validationRelatedFieldName: string) =>
                        validationRelatedFieldName === metaFieldName
                )
            )
            .filter(
                (validationMetaFieldName: string) =>
                    validationMetaFieldName !== undefined &&
                    validationMetaFieldName !== null
            );
        if (validationMetaFieldNames.length > 0) {
            this.validationEmitter.emit();
        }
        return this.kbService.getMetaFields(this.entityCategorySystemName).pipe(
            mergeMap((metaFields: MetaField[]) => {
                const messages$: Observable<
                    string
                >[] = validationMetaFieldNames.map((metaFieldName: string) =>
                    this.messageService.getMessage(
                        metaFields
                            .find(
                                (metaField: MetaField) =>
                                    metaField.getSystemName() === metaFieldName
                            )
                            .getDisplayNameMsgId()
                    )
                );
                const validationMainMsg: Observable<string> = this.messageService.getMessage(
                    'de_hierarchical_search_validation'
                );
                messages$.push(validationMainMsg);
                return zip(...messages$);
            }),
            map((messages: string[]) => {
                const validationMainMsg = messages.pop();
                return messages.length > 0
                    ? validationMainMsg.replace('{0}', messages.join(', '))
                    : '';
            })
        );
    }

    public trackByFunc(
        index: number,
        item: HierarchicalSearchItem | Observable<any>
    ): number {
        if (item instanceof Observable) {
            return index;
        }
        return item.entityItem.id;
    }

    private initHierarchicalSearchComponent(): void {
        this.initRootCategorySystemName()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (data: any) => {
                    this.initSelectedEntity();
                },
                err => console.error(err)
            );
    }

    private initSelectedEntity(): void {
        let fieldValueMapping: Map<string, number> = Map<string, number>();

        if (this.entity !== undefined && this.entity !== null) {
            const classifierNames$: Observable<string>[] = [];

            fieldValueMapping = fieldValueMapping.withMutations(mutable => {
                this.categoryFieldsMapping.forEach(
                    (
                        metaField: string,
                        categoryName: string,
                        map: Map<string, string>
                    ) => {
                        const classifier = this.entity.getProperty<Classifier>(
                            metaField
                        ).value;
                        if (classifier !== null) {
                            classifierNames$.push(
                                this.classifierService.getEntityName(
                                    categoryName,
                                    classifier
                                )
                            );
                            mutable.set(metaField, classifier.getId());
                        }
                    }
                );
            });

            zip(...classifierNames$)
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    (classifierNames: string[]) => {
                        this.selectedEntity = new HierarchicalSearchItem(
                            classifierNames.reverse().join(', '),
                            fieldValueMapping,
                            null
                        );
                        this.searchCtrl.setValue(
                            this.selectedEntity.displayName
                        );
                    },
                    err => console.error(err)
                );
        }
    }

    private initRootCategorySystemName(): Observable<any> {
        const firstMetaCategoryId: MetaCategoryId = new MetaCategoryId(
            this.selectedItemCategories[this.selectedItemCategories.length - 1]
        );

        const ancestorsMetaCategoryIds: Observable<MetaCategoryId[]> = this.kbService.getAncestorsMetaCategoryIds(
            firstMetaCategoryId
        );
        return ancestorsMetaCategoryIds.pipe(
            map((metaCategoryIds: MetaCategoryId[]) => {
                if (metaCategoryIds.length > 0) {
                    this.rootCategoryName = metaCategoryIds[
                        metaCategoryIds.length - 1
                    ].getSystemName();
                } else {
                    throw new Error('There is no root category');
                }
                return this.rootCategoryName;
            })
        );
    }

    private onFilterValueChange(): void {
        this.filteredOptions = this.searchCtrl.valueChanges.pipe(
            debounceTime(500),
            mergeMap(value => {
                const searchValue: string =
                    typeof value === 'string' ? value : value.displayName;
                return value === '' || value === null
                    ? of(new ServiceResponseDefault<HierarchicalSearchItem[]>())
                    : this.searchEntities(searchValue);
            }),
            map(
                (
                    serviceResponse: ServiceResponseDefault<
                        HierarchicalSearchItem[]
                    >
                ) => {
                    this.totalRows =
                        serviceResponse.getMeta() !== undefined
                            ? serviceResponse.getMeta().getTotalRowCount()
                            : 0;

                    if (
                        !this.autoComplete.autocomplete._isOpen &&
                        !this.selectedEntity
                    ) {
                        this.autoComplete.openPanel();
                    }

                    return (this.filteredData =
                        serviceResponse.getData() !== undefined
                            ? serviceResponse.getData()
                            : []);
                }
            )
        );
    }

    private searchEntities(
        query: string
    ): Observable<ServiceResponse<HierarchicalSearchItem[]>> {
        const filter: HierarchicalSearchFilterOptions = new HierarchicalSearchFilterOptions(
            query,
            this.selectedItemCategories,
            this.currentLanguageId
        );
        const paging: PagingOptions = new PagingOptions(0, this.rowsToShow);

        return this.searchService.loadHierarchicalEntities(
            this.categoryFieldsMapping,
            this.rootCategoryName,
            filter,
            paging
        );
    }

    private bindSelectedValueToEntity(
        selectedEntity: HierarchicalSearchItem
    ): void {
        let category: string;
        let hasMatch = false;

        // resetting Main Entity old Values
        this.categoryFieldsMapping.forEach(
            (
                metaFieldName: string,
                categoryName: string,
                map: Map<string, string>
            ) => {
                this.entity.getProperty(metaFieldName).value = null;
            }
        );

        let lastLevelClassifierData: HierarchicalSelectChangedData = null;

        selectedEntity.fieldValueMapping.forEach(
            (
                classifierId: number,
                metaFieldName: string,
                map: Map<string, number>
            ) => {
                hasMatch = false;
                this.categoryFieldsMapping.forEach(
                    (metaField: string, categoryName: string) => {
                        if (!hasMatch && metaField === metaFieldName) {
                            category = categoryName;
                            hasMatch = true;
                        }
                    }
                );
                if (!hasMatch) {
                    throw new Error(
                        `No Category Name mapping for ${metaFieldName} Meta Field name.`
                    );
                }

                lastLevelClassifierData = {
                    categoryName: category,
                    metaFieldName: metaFieldName,
                    value: classifierId,
                };

                this.classifierService
                    .loadClassifier(category, classifierId)
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe(
                        (classifier: Classifier) => {
                            this.entity.getProperty<Classifier>(
                                metaFieldName
                            ).value = classifier;
                        },
                        err => console.error(err)
                    );
            }
        );

        this.valueChange.emit(lastLevelClassifierData);
    }
}
