import {mergeMap} from 'rxjs/operators';
/**
 * @author tatevik.marikyan
 * @since 30/07/2018
 */
import {Injectable} from '@angular/core';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Meta, ServiceResponse, ServiceResponseDefault} from '@synisys/idm-de-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {HierarchicalEntityItem, HierarchicalSearchItem} from '../model';
import {Map} from 'immutable';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';

@Injectable()
export class HierarchicalSearchDeserializationService {

  constructor(private currentLanguageProvider: CurrentLanguageProvider,
              private languageService: LanguageService) {
  }

  public deserializeEntities(hierarchicalEntitiesData$: Observable<any>,
                             categoryFieldsMapping: Map<string, string>):
    Observable<ServiceResponse<HierarchicalSearchItem[]>> {
    const serviceResponse: ServiceResponseDefault<HierarchicalSearchItem[]> =
      new ServiceResponseDefault<HierarchicalSearchItem[]>();
    const language$: Observable<Language> = this.currentLanguageProvider.getCurrentLanguage();

    return zip(hierarchicalEntitiesData$, language$).pipe(
                     mergeMap((data: any) => {
                       const entitiesData = data[0];
                       const currentLanguageId: number = data[1].getId();

                       const entityItems: HierarchicalEntityItem[] = entitiesData.data;
                       let searchItems: HierarchicalSearchItem[] = [];

                       if ((entityItems.length > 0)) {
                         searchItems = entityItems.map((entityItem: HierarchicalEntityItem) => {
                           return this.createHierarchicalSearchItem(entityItem, categoryFieldsMapping,
                                                                    currentLanguageId);
                         });
                       }

                       // init service response
                       const meta: Meta = new Meta();
                       meta.setTotalRowCount(entitiesData.meta.totalRowCount);
                       serviceResponse.setMeta(meta);
                       serviceResponse.setData(searchItems);

                       return of(serviceResponse);
                     }));
  }

  private createHierarchicalSearchItem(entityItem: HierarchicalEntityItem,
                                       categoryFieldsMapping: Map<string, string>,
                                       currentLanguageId: number): HierarchicalSearchItem {
    const fieldValueMapping: Map<string, number> = this.constructFieldValueMapping(entityItem,
                                                                                   categoryFieldsMapping);
    const displayName: string = this.constructDisplayName(entityItem, currentLanguageId);

    return new HierarchicalSearchItem(displayName, fieldValueMapping, entityItem);
  }

  private constructFieldValueMapping(entityItem: HierarchicalEntityItem,
                                     categoryFieldsMapping: Map<string, string>): Map<string, number> {
    let fieldValueMapping: Map<string, number> = Map<string, number>();

    if (entityItem.path.length > 0) {
      fieldValueMapping = fieldValueMapping.withMutations(mutable => {
        entityItem.path.forEach((pathItem: HierarchicalEntityItem) => {
          const constructField = categoryFieldsMapping.get(pathItem.category);
          mutable.set(constructField, pathItem.id);
        });
      });

    }

    const metaFieldName = categoryFieldsMapping.get(entityItem.category);
    return fieldValueMapping.set(metaFieldName, entityItem.id);

  }

  private constructDisplayName(entityItem: HierarchicalEntityItem, currentLanguageId: number): string {
    let displayNames: string[] = [];

    if (entityItem.path.length > 0) {
      displayNames = entityItem.path.map((pathItem: HierarchicalEntityItem) => {
        return pathItem.name[currentLanguageId];
      });
    }
    displayNames.push(entityItem.name[currentLanguageId]);

    return displayNames.reverse().join(', ');
  }

}
