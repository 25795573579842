import {map, takeUntil} from 'rxjs/operators';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    Language,
    MultilingualString,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {HierarchicalSelectSettingsComponent} from './hierarchical-select-settings';
import {
    Validation,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {
    Classifier,
    ClassifierService,
} from '@synisys/idm-classifier-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {MetaFieldId} from '@synisys/idm-kb-service-client-js';
import {Map} from 'immutable';
import {stringToMultilingual} from '../utilities';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import './hierarchical-select.component.scss';
import {HierarchicalSelectChangedData} from '../header-three-dot-menu/hierarchical-select-last-level-data.model';

/**
 * @author tatevik.marikyan
 * @since 25/06/2018
 */

@Component({
               moduleId   : module.id + '',
               selector   : 'hierarchical-select',
               templateUrl: './hierarchical-select.component.html',
           })
@ControlMetadata({
                     cellCount: 4,
                     groups   : [{identity: 'input', name: 'Input Elements'}],

                     // todo TatevK changes this icon to svg
                     iconInfo: 'fa-bars',

                     name          : 'Hierarchical Picker',
                     nameKey       : 'de.controls.hierarchical.title',
                     settings      : {
                         main: HierarchicalSelectSettingsComponent,
                     },
                     template      : `
              <hierarchical-select
                  [id]="'%{id}'"
                  [isReadonly]="%{isReadonly}"
                  [title]="%{title}"
                  [titleKey]="%{titleKey}"
                  [entityCategorySystemName]="%{entityCategorySystemName}"
                  [density]="%{density}"
                  [entity]="contextEntity"
                  [validations]="validations"
                  [selectedItemCategories]="%{selectedItemCategories}"
                  [selectedItemFields]="%{selectedItemFields}"
                  [switchModes]="%{switchModes}"
                   (validationEmitter)="%{validationEmittedFor}"
                  [viewMode]="%{viewMode}"
                  [editable]="%{editable}">
              </hierarchical-select>
            `,
                     defaultActions: {
                         validationEmittedFor: 'validationEmittedFor',
                     },
                 })
export class HierarchicalSelectComponent extends AbstractDestructionSubject
    implements OnInit, OnChanges, OnDestroy {
    @Input()
    public id: string;
    @Input()
    public isReadonly: boolean;
    @Input()
    public title: MultilingualString | string;
    @Input()
    public titleKey: string;
    @Input()
    public entityCategorySystemName: string;
    @Input()
    public density: string;
    @Input()
    public entity: Entity;
    @Input()
    public validations: Validation[] = [];
    @Input()
    public switchModes: string;
    @Input()
    public selectedItemCategories: string;
    @Input()
    public selectedItemFields: string;
    @Input()
    public viewMode: boolean;
    @Input()
    public editable: boolean;
    @Output()
    public valueChange = new EventEmitter<HierarchicalSelectChangedData>();
    @Output()
    public validationEmitter: EventEmitter<void> = new EventEmitter<void>();

    public switchModesArray: string[];
    public selectedItemCategoriesArray: string[];
    public selectedItemFieldsArray: string[];
    public isReady = false;
    public languages: Language[];
    public currentLanguageId: number;
    public currentSwitchMode: string;
    public requiredMetaFields: MetaFieldId[];
    public categoryFieldsMapping: Map<string, string> = Map<string, string>();
    public viewModeValue: string;

    constructor(
        private currentLanguageProvider: CurrentLanguageProvider,
        private classifierService: ClassifierService,
        private languageService: LanguageService,
        private ref: ChangeDetectorRef,
        private validationService: ValidationService
    ) {
        super();
    }

    public ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
        if (changes['switchModes'] && changes['switchModes'].currentValue) {
            this.currentSwitchMode = this.entity
                                     ? changes['switchModes'].currentValue[0]
                                     : ['drillDown'];
        }
        if (changes['entity'] && changes['entity'].currentValue) {
            this.init();
        }
    }

    public ngOnInit(): void {
        this.parseStringOfArrays();
        this.initComponentId();
    }

    public onValueChange(lastLevelData: HierarchicalSelectChangedData): void {
        this.valueChange.emit(lastLevelData);
    }

    public parseStringOfArrays() {
        if (this.switchModes === '') {
            this.switchModesArray = [];
        } else {
            this.switchModesArray =
                typeof this.switchModes === 'string'
                ? JSON.parse(this.switchModes)
                : this.switchModes;
        }
        if (this.selectedItemCategories === '') {
            this.selectedItemCategoriesArray = [];
        } else {
            this.selectedItemCategoriesArray =
                typeof this.selectedItemCategories === 'string'
                ? JSON.parse(this.selectedItemCategories)
                : this.selectedItemCategories;
        }
        if (this.selectedItemFields === '') {
            this.selectedItemFieldsArray = [];
        } else {
            this.selectedItemFieldsArray =
                typeof this.selectedItemFields === 'string'
                ? JSON.parse(this.selectedItemFields)
                : this.selectedItemFields;
        }
    }

    public isRequired(): boolean {
        let isRequired = false;
        this.requiredMetaFields.forEach(nextItem => {
            if (
                this.selectedItemFieldsArray.indexOf(nextItem.getSystemName()) >
                -1
            ) {
                isRequired = true;
            }
        });
        return isRequired;
    }

    public validationEmitterHandler(): void {
        this.validationEmitter.emit();
    }

    public switchMode(switchMode: string): void {
        this.currentSwitchMode = switchMode;
    }

    public checkOneModeCase() {
        if (this.switchModesArray && this.switchModesArray.length === 1) {
            this.currentSwitchMode = this.switchModesArray[0];
        }
    }

    public trackByFunc(index: number, item: string) {
        return index;
    }

    /**
     * Initializes JSON Title to MultilingualString Title
     */
    private initTitle(): void {
        if (
            this.title !== undefined &&
            this.title !== null &&
            this.title !== '' &&
            !(this.title instanceof MultilingualString)
        ) {
            this.title = stringToMultilingual(this.title);
        }
    }

    private initCategoryFieldsMapping(): void {
        if (
            this.selectedItemFieldsArray &&
            this.selectedItemCategoriesArray &&
            this.selectedItemFieldsArray.length !==
            this.selectedItemCategoriesArray.length
        ) {
            throw new Error('Items length is not compatible');
        }
        if (this.selectedItemFieldsArray) {
            this.selectedItemFieldsArray.forEach(
                (itemField: string, index: number) => {
                    this.categoryFieldsMapping = this.categoryFieldsMapping.set(
                        this.selectedItemCategoriesArray[index],
                        itemField
                    );
                }
            );
        }
    }

    private init() {
        // First item will be always default mode for switch control
        this.isReady = false;
        this.parseStringOfArrays();
        this.currentSwitchMode = this.switchModesArray[0];
        this.initCategoryFieldsMapping();

        const languages$: Observable<Language[]> = this.languageService.getInputLanguages();
        const currentLanguageId$: Observable<Language> = this.currentLanguageProvider.getCurrentLanguage();
        const requiredMetaFields$: Observable<MetaFieldId[]> = this
                                                                   .entityCategorySystemName
                                                               ? this.validationService.getCategoryRequiredMetaFieldIds(
                this.entityCategorySystemName
            )
                                                               : of([]);
        const viewModeValue$: Observable<string> = this.getViewModeValue();

        zip(languages$, currentLanguageId$, requiredMetaFields$, viewModeValue$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                data => {
                    this.languages = data[0];
                    this.currentLanguageId = data[1].getId();
                    this.requiredMetaFields = data[2];
                    this.viewModeValue = data[3];

                    this.checkOneModeCase();
                    this.initTitle();
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    private getViewModeValue(): Observable<string> {
        if (this.viewMode) {
            const classifierNames$: Observable<string>[] = [];
            if (this.entity) {
                this.categoryFieldsMapping.forEach(
                    (
                        metaField: string,
                        categoryName: string,
                        map: Map<string, string>
                    ) => {
                        const classifier = this.entity.getProperty<Classifier>(
                            metaField
                        ).value;
                        if (classifier !== null) {
                            classifierNames$.push(
                                this.classifierService.getEntityName(
                                    categoryName,
                                    classifier
                                )
                            );
                        }
                    }
                );
            }

            if (classifierNames$.length !== 0) {
                return zip(...classifierNames$).pipe(
                    map((classifierNames: string[]) => {
                        return classifierNames.join(' > ');
                    })
                );
            } else {
                return of(undefined);
            }
        } else {
            return of('');
        }
    }

    private initComponentId(): void {
        this.id += this.selectedItemFieldsArray.join('-');
    }
}
