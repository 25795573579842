import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisDocumentSettingsComponent} from './sis-document-settings.component';
import {PolicyOptionDto} from '@synisys/idm-policy-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {first, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {isNil} from 'lodash';
import {of} from 'rxjs/observable/of';
import {noop} from 'rxjs/util/noop';
import {zip} from 'rxjs/observable/zip';

@Component({
    moduleId: module.id + '',
    selector: 'sis-dynamic-document',
    templateUrl: 'sis-dynamic-document.component.html',
})
@ControlMetadata({
    name: 'Document',
    cellCount: 3,
    settings: {
        main: SisDocumentSettingsComponent,
    },
    template: `
            <sis-dynamic-document
                [id]="'%{id}'"
                [isReadonly]="%{isReadonly}"
                [documentId]="%{field}"
                [entity]="entity"
                [category]="category"
                [contextCategory]="contextCategory"
                [fieldName]="'%{field-name}'"
                [filePolicy]="%{filePolicy}"
                [height]="%{height}"
                [documentPreviewEnabled]="%{documentPreviewEnabled}"
                [showUploadDate]="%{showUploadDate}"
                [showDocumentSize]="%{showDocumentSize}"
                [showUploadedBy]="%{showUploadedBy}"
                (documentUpload)="%{onDocumentUpload}"
                (documentRemove)="%{onDocumentRemove}">
            </sis-dynamic-document>
                     `,
    isFieldBound: true,
    defaultActions: {
        onDocumentUpload: 'submitNestedDocument',
        onDocumentRemove: 'removeDocument',
    },
})
export class SisDynamicDocumentComponent implements OnInit {
    private static readonly ROOT_ID = 'rootId';

    @Output()
    public documentUpload = new EventEmitter<number>();
    @Output()
    public documentRemove = new EventEmitter<number>();
    public documentRootName$: Observable<string>;

    private _isReadonly: boolean;
    private _documentId: number;
    private _entity: Entity;
    private _category: string;
    private _filePolicy: PolicyOptionDto;
    private _id: string;
    private _height: number;
    private _contextCategory: string | undefined;
    private _documentPreviewEnabled = true;
    private _fieldName: string;
    private _metaData: Map<string, any>;

    private _showUploadDate = true;
    private _showDocumentSize = true;
    private _showUploadedBy = true;

    constructor(private kbService: KbService) {}

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    get documentId(): number {
        return this._documentId;
    }

    @Input()
    set documentId(value: number) {
        this._documentId = value;
    }

    get entity(): Entity {
        return this._entity;
    }

    @Input()
    set entity(value: Entity) {
        this._entity = value;
    }

    get category(): string {
        return this._category;
    }

    @Input()
    set category(value: string) {
        this._category = value;
    }

    get filePolicy(): PolicyOptionDto {
        return this._filePolicy;
    }

    @Input()
    set filePolicy(value: PolicyOptionDto) {
        this._filePolicy = value;
    }

    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    get height(): number {
        return this._height;
    }

    @Input()
    set height(value: number) {
        this._height = value;
    }

    get documentPreviewEnabled(): boolean {
        return this._documentPreviewEnabled;
    }

    @Input()
    set documentPreviewEnabled(value: boolean) {
        this._documentPreviewEnabled = value;
    }

    get showUploadDate(): boolean {
        return this._showUploadDate;
    }

    @Input()
    set showUploadDate(value: boolean) {
        this._showUploadDate = value;
    }

    get showDocumentSize(): boolean {
        return this._showDocumentSize;
    }

    @Input()
    set showDocumentSize(value: boolean) {
        this._showDocumentSize = value;
    }

    get showUploadedBy(): boolean {
        return this._showUploadedBy;
    }

    @Input()
    set showUploadedBy(value: boolean) {
        this._showUploadedBy = value;
    }

    get fieldName(): string {
        return this._fieldName;
    }

    @Input()
    set fieldName(value: string) {
        this._fieldName = value;
    }

    get contextCategory(): string {
        return this._contextCategory;
    }

    @Input()
    set contextCategory(value: string | undefined) {
        this._contextCategory = value;
    }

    get metaData(): Map<string, any> {
        return this._metaData;
    }

    public ngOnInit(): void {
        this.documentRootName$ =
            this.contextCategory === this.category ||
            isNil(this.contextCategory)
                ? of(this.fieldName)
                : this.kbService.getMetaFields(this.category).pipe(
                      map(fields =>
                          fields.find(
                              field =>
                                  field.getCompoundCategorySystemName() ===
                                  this.contextCategory
                          )
                      ),
                      map(field =>
                          isNil(field) ? this.fieldName : field.getSystemName()
                      )
                  );
        this._metaData = new Map<string, any>();
        this.putRootIdInMetaDataIfPresent();
    }

    public documentUploaded(value: number): void {
        this.documentUpload.emit(value);
    }

    public documentRemoved(value: number): void {
        this.documentRemove.emit(value);
    }

    public onDocumentMetadataUpdate(updatedMetaData: Map<string, any>): void {
        this._metaData = updatedMetaData;
        this.putRootIdInMetaDataIfPresent();
    }

    private putRootIdInMetaDataIfPresent(): void {
        if (!isNil(this.metaData.get(SisDynamicDocumentComponent.ROOT_ID))) {
            return;
        }
        zip(
            this.kbService.getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(this.category)
            ),
            this.kbService.getMetaFields(this.category)
        )
            .pipe(
                tap((data: [MetaCategory, MetaField[]]) => {
                    if (data[0].getIsRoot) {
                        this.metaData.set(
                            SisDynamicDocumentComponent.ROOT_ID,
                            this._entity.getId()
                        );
                        return;
                    }
                    const rootCategoryField = data[1].find(
                        (metaField: MetaField) =>
                            metaField.getType() === MetaFieldType.PARENT
                    );
                    if (rootCategoryField) {
                        this.metaData.set(
                            SisDynamicDocumentComponent.ROOT_ID,
                            this._entity.getProperty<number>(
                                rootCategoryField.getSystemName()
                            ).value
                        );
                    }
                }),
                first()
            )
            .subscribe(noop, console.error);
    }
}
