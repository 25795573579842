import {Component} from "@angular/core";
import {CancelButtonComponent} from "../../cancel-button/cancel-button.component";

@Component({
    moduleId: module.id + '',
    selector: 'mobile-cancel-button',
    template: `
      <button mat-icon-button
              [id]="id" (click)="onClick()">
        <svg class="sis-icon">
          <use xlink:href="icons/core.svg#close"></use>
        </svg>
      </button>
    `
})
export class MobileCancelButtonComponent extends CancelButtonComponent {}
