import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {PolicyOptionDto, PolicyService} from '@synisys/idm-policy-client-js';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';

@Component({
    moduleId: module.id + '',
    selector: 'sis-document-settings',
    templateUrl: 'sis-document-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
@ControlMetadata({
    template: `
                      <sis-document-settings [state]="%{state}" [currentLanguageId]="%{currentLanguageId}">
                      </sis-document-settings>`,
})
export class SisDocumentSettingsComponent implements OnInit {
    public filePolicyOptions$: Observable<PolicyOptionDto[]>;
    @Input()
    public currentLanguageId: number;
    private _selectedPolicyOption: PolicyOptionDto | undefined;

    get selectedPolicyOption(): PolicyOptionDto {
        return this._selectedPolicyOption;
    }

    @Input()
    set selectedPolicyOption(value: PolicyOptionDto | undefined) {
        this._selectedPolicyOption = value;
        this.communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    filePolicy: this.selectedPolicyOption,
                },
            })
        );
    }

    @Input()
    public set state(value: object) {
        if (value['filePolicy']) {
            this._selectedPolicyOption = value['filePolicy'];
        }
    }

    public constructor(
        private policyService: PolicyService,
        private communicationService: CommunicationService
    ) {}

    public ngOnInit(): void {
        this.filePolicyOptions$ = this.policyService
            .getPolicyOptions()
            .pipe(
                map((policyOptionDtos: PolicyOptionDto[]) =>
                    policyOptionDtos.filter(
                        policyOptionDto =>
                            policyOptionDto.policyId ===
                            '2c191221-9041-11e6-821f-0050568f767f'
                    )
                )
            );
    }

    public policyOptionChange(
        selectedPolicyId: string,
        policyOptions: PolicyOptionDto[]
    ): void {
        this.selectedPolicyOption = policyOptions.find(
            policyOption => policyOption.id === selectedPolicyId
        );
    }
}
