import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';

import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
} from '@synisys/idm-kb-service-client-js';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {EventBase} from '@synisys/idm-session-data-provider-api-js';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import './header-mobile.component.scss';
import {ExportTemplate} from '../header-information';
import {
    UserHelpItem,
    userHelpItemFromObject,
} from '../../models/user-help-item.model';

@Component({
    moduleId: module.id + '',
    selector: 'header-mobile',
    templateUrl: 'header-mobile.component.html',
})
@ControlMetadata({
    template: `
      <header-mobile [id]="'%{id}'" [entity]="entity" [categoryName]="category" [isView]="actions.isViewForm()"
                      [userHelpItemsAsObjects]="%{userHelpItems}"
                      [workflowActions]="workflowActions" [currentLanguageId]="currentLanguageId"
                      [title_msg]="%{title_msg}" [withDeleteButton]="%{withDeleteButton}" [actionNotifier]="actionNotifier"
                      [hasEditPermission]="%{hasEditPermission}" (onWfSelectEvent)="%{onWfSelect}"
                      [isReadonly]="%{isReadonly}"
                      (editClickEvent)="%{editClick}" (cancelEvent)="%{cancelEvent}" (saveCloseEvent)="%{saveCloseEvent}">
      </header-mobile>
    `,
    isHeader: true,
    defaultActions: {
        hasEditPermission: 'hasEditPermission',
        onWfSelect: 'onDoWorkflowAction',
        editClick: 'toDefaultEditForm',
        cancelEvent: 'onCancel',
        saveCloseEvent: 'onSaveAndClose',
    },
    name: 'Mobile Header',
    nameKey: 'de.controls.mobile_header.title',
})
export class HeaderMobileComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy {
    // <editor-fold desc="Getters and Setters">
    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    get entity(): Entity {
        return this._entity;
    }

    @Input()
    set entity(value: Entity) {
        this._entity = value;
    }

    get categoryName(): string {
        return this._categoryName;
    }

    @Input()
    set categoryName(value: string) {
        this._categoryName = value;
    }

    get title_msg(): string {
        return this._title_msg;
    }

    @Input()
    set title_msg(value: string) {
        this._title_msg = value;
    }

    get isView(): boolean {
        return this._isView !== undefined ? this._isView : this.isReadonly;
    }

    @Input()
    set isView(value: boolean) {
        this._isView = value;
    }

    get userHelpItems(): UserHelpItem[] {
        return this._userHelpItems;
    }

    @Input()
    set userHelpItems(value: UserHelpItem[]) {
        this._userHelpItems = value;
    }

    @Input()
    set userHelpItemsAsObjects(values: object[]) {
        this.userHelpItems = values ? values.map(userHelpItemFromObject) : [];
    }

    get workflowActions(): ActionDto[] {
        return this._workflowActions;
    }

    @Input()
    set workflowActions(value: ActionDto[]) {
        this._workflowActions = value;
    }

    get currentLanguageId(): number {
        return this._currentLanguageId;
    }

    @Input()
    set currentLanguageId(value: number) {
        this._currentLanguageId = value;
    }

    get hasEditPermission(): boolean {
        return this._hasEditPermission;
    }

    @Input()
    set hasEditPermission(value: boolean) {
        this._hasEditPermission = value;
    }

    get withDeleteButton(): boolean {
        return this._withDeleteButton;
    }

    @Input()
    set withDeleteButton(value: boolean) {
        this._withDeleteButton = value;
    }

    get actionNotifier(): Observable<any> {
        return this._actionNotifier;
    }

    @Input()
    set actionNotifier(value: Observable<any>) {
        this._actionNotifier = value;
    }

    @Input()
    public isReadonly = false;

    get exportTemplates(): ExportTemplate[] {
        return this._exportTemplates;
    }

    @Input()
    set exportTemplates(value: ExportTemplate[]) {
        this._exportTemplates = value;
    }

    // </editor-fold>

    @Output()
    public onWfSelectEvent: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public editClickEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public cancelEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public saveCloseEvent: EventEmitter<void> = new EventEmitter<void>();

    // Input fields
    private _id: string;
    private _entity: Entity;
    private _categoryName: string;
    private _title_msg: string;
    private _isView = false;
    private _userHelpItems: UserHelpItem[];
    private _workflowActions: ActionDto[] = [];
    private _currentLanguageId: number;
    private _hasEditPermission: boolean;
    private _withDeleteButton: boolean;
    private _actionNotifier: Observable<any>;
    private _exportTemplates: ExportTemplate[];

    // private fields
    private _title = '';

    constructor(
        private _classifierService: ClassifierService,
        private _kbService: KbService,
        private _messageService: MessageService,
        private _eventBase: EventBase
    ) {
        super();
    }

    public ngOnInit(): void {
        const nameMeasure$ = this._classifierService.getEntityName(
            this.categoryName,
            this.entity
        );
        const title$ = this.title_msg
            ? of(this.title_msg)
            : this._kbService
                  .getMetaCategoryByMetaCategoryId(
                      new MetaCategoryId(this.categoryName)
                  )
                  .pipe(
                      map((metaCategory: MetaCategory) =>
                          metaCategory.getDisplayNameMsgId()
                      ),
                      mergeMap((msg: string) =>
                          this._messageService.getMessage(msg)
                      )
                  );

        zip(title$, nameMeasure$)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (data: string[]) => {
                    const title = data[0];
                    const nameMeasure = data[1];
                    if (title) {
                        this._title = nameMeasure
                            ? title + ` : ${nameMeasure}`
                            : title;
                    }
                },
                err => console.error(err)
            );
    }

    public onMenuClicked(): void {
        this._eventBase.publish('de-header-menu-clicked', true);
    }
}
