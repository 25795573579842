/**
 * @author tatevik.marikyan
 * @since 02/07/2018
 */

import {Classifier} from "@synisys/idm-classifier-service-client-js";
import {MetaCategory} from "@synisys/idm-kb-service-client-js";

export class HierarchicalDrillDownItem {

  /**
   * Model for each Hierarchical Drill Down Item
   * @param {Classifier} value
   * @param {MetaCategory} metaCategory
   * @param {string} metaFieldName
   */
  constructor(public value: Classifier,
              public metaCategory: MetaCategory,
              public metaFieldName: string,
              public isRequired?: boolean,) {
  }
}
