import {
    ExportTemplate,
    Extension,
} from '../header-information/header-settings.component';
import {ExportType} from '@synisys/idm-export-client-js';
import {PersonalizedTemplateView} from '@synisys/idm-export-templates-service-client-js';

export interface FormExportTemplate extends ExportTemplate {
    isPersonalized: boolean;
}

export function extensionToExportType(extension: Extension): ExportType {
    switch (extension) {
        case 'PDF':
            return ExportType.PDF;
        case 'RTF':
            return ExportType.RTF;
        case 'XLS':
            return ExportType.EXCEL;
    }
    throw new Error(`Unsupported extension type: ${extension}`);
}

export function exportTypeToExtension(exportType: ExportType): Extension {
    switch (exportType) {
        case ExportType.PDF:
            return 'PDF';
        case ExportType.RTF:
            return 'RTF';
        case ExportType.EXCEL:
            return 'XLS';
    }
    throw new Error(`Unsupported extension type: ${exportType}`);
}

export function formTemplateFromPersonalized(
    template: PersonalizedTemplateView
): FormExportTemplate {
    return {
        id: template.uuid,
        name: template.name,
        extension: exportTypeToExtension(template.exportType),
        isPersonalized: true,
    };
}
