import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {of} from 'rxjs/observable/of';
import {zip} from 'rxjs/observable/zip';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {
    Classifier,
    ClassifierService,
} from '@synisys/idm-classifier-service-client-js';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {FormattingService} from '../../services';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import './header-information-group.component.scss';
import {
    Language,
    MultilingualString,
    MultilingualStringBuilder,
} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Title} from '@angular/platform-browser';
import {combineLatest} from 'rxjs/observable/combineLatest';

@Component({
    moduleId: module.id + '',
    selector: 'header-information',
    templateUrl: 'header-information-group.component.html',
})
export class HeaderInformationGroupComponent extends AbstractDestructionSubject
    implements OnDestroy, OnChanges {
    public get hasWorkflow(): boolean {
        return this._hasWorkflow;
    }

    public set hasWorkflow(value: boolean) {
        this._hasWorkflow = value;
    }

    public get metaFields(): MetaField[] {
        return this._metaFields;
    }

    public set metaFields(value: MetaField[]) {
        this._metaFields = value;
    }

    get lastUpdatedUser(): string {
        return this._lastUpdatedUser;
    }

    set lastUpdatedUser(value: string) {
        this._lastUpdatedUser = value;
    }

    get workFlowState(): string {
        return this._workFlowState;
    }

    set workFlowState(value: string) {
        this._workFlowState = value;
    }

    get nameMeasure(): string {
        return this._nameMeasure;
    }

    set nameMeasure(value: string) {
        this._nameMeasure = value;
    }

    get createdUser(): string {
        return this._createdUser;
    }

    set createdUser(value: string) {
        this._createdUser = value;
    }

    @Input()
    public id: string;

    @Input()
    public caption: string;

    @Input()
    public title_msg: string = null;

    @Input()
    public entityName: string = null;

    @Input()
    public entity: Entity;

    @Input()
    public categoryName: string;

    @Input()
    public currentLanguageId: number;

    @Input()
    public createdUserFieldName: string = 'CreatedUserID';

    private _hasWorkflow = false;

    private _metaFields: MetaField[];

    private _lastUpdatedUser: string;

    private _createdUser: string;

    private _workFlowState = '';

    private _nameMeasure: string;

    private _titleDisplayName: MultilingualString;

    private createdMessage$: ReplaySubject<string> = new ReplaySubject(1);
    private modifiedMessage$: ReplaySubject<string> = new ReplaySubject(1);

    constructor(
        private _kbService: KbService,
        private _classifierService: ClassifierService,
        private messageService: MessageService,
        private formattingService: FormattingService,
        private currentLanguageProvider: CurrentLanguageProvider,
        private titleService: Title
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['entity']) {
            this.handleViewData()
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(
                    () => {
                        if (this._titleDisplayName) {
                            this.titleService.setTitle(
                                this._titleDisplayName.getValue(
                                    this.currentLanguageId
                                )
                            );
                        }
                    },
                    err => console.error(err)
                );
        }
    }

    public getLastUpdatedDate(): Date {
        return this.entity
            ? this.entity.getProperty<Date>('DateUpdated').value
            : undefined;
    }

    public getCreatedDate(): Date {
        return this.entity
            ? this.entity.getProperty<Date>('DateCreated').value
            : undefined;
    }

    public getTitle(): Observable<string> {
        if (this.title_msg) {
            if (this.nameMeasure) {
                return this.messageService
                    .getMessage(this.title_msg)
                    .pipe(
                        map(
                            (message: string) =>
                                message + ` : ${this.nameMeasure}`
                        )
                    );
            }
            return this.messageService.getMessage(this.title_msg);
        } else if (this.caption) {
            if (this.nameMeasure) {
                return of(`${this.caption} : ${this.nameMeasure}`);
            }
            return of(this.caption);
        }
        return of('');
    }

    public getModifiedMessage(): Observable<string> {
        return this.modifiedMessage$;
    }

    public getCreatedMessage(): Observable<string> {
        return this.createdMessage$;
    }

    get titleDisplayName(): MultilingualString {
        return this._titleDisplayName;
    }

    protected handleViewData(): Observable<boolean> {
        const updatedUserClassifierEntity = this.entity
            ? this.entity.getProperty<Classifier>('UpdatedUserID').value
            : undefined;
        const createdUserClassifierEntity = this.entity
            ? this.entity.getProperty<Classifier>(this.createdUserFieldName)
                  .value
            : undefined;
        const lastUpdatedUser$ = updatedUserClassifierEntity
            ? this._classifierService.getEntityName(
                  'User',
                  updatedUserClassifierEntity
              )
            : of('');
        const createdUser$ = createdUserClassifierEntity
            ? this._classifierService.getEntityName(
                  'User',
                  createdUserClassifierEntity
              )
            : of('');
        const nameMeasure$ = this.entityName
            ? of(this.entityName)
            : this._classifierService.getEntityName(
                  this.categoryName,
                  this.entity
              );

        const metaFields$ = this._kbService.getMetaFields(this.categoryName);

        const titleDisplayName$ = this._kbService
            .getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(this.categoryName)
            )
            .pipe(
                map((metaCategory: MetaCategory) =>
                    metaCategory.getDisplayNameMultilingual()
                )
            );

        const titleMsg$ = this.title_msg
            ? of(this.title_msg)
            : this._kbService
                  .getMetaCategoryByMetaCategoryId(
                      new MetaCategoryId(this.categoryName)
                  )
                  .pipe(
                      map((metaCategory: MetaCategory) =>
                          metaCategory.getDisplayNameMsgId()
                      )
                  );
        const currentLanguageId$ = this.currentLanguageId
            ? of(this.currentLanguageId)
            : this.currentLanguageProvider
                  .getCurrentLanguage()
                  .pipe(map((language: Language) => language.getId()));

        return zip(
            lastUpdatedUser$,
            createdUser$,
            nameMeasure$,
            titleMsg$,
            metaFields$,
            titleDisplayName$
        ).pipe(
            mergeMap(data => {
                this._lastUpdatedUser = data[0];
                this._createdUser = data[1];
                this._nameMeasure = data[2];
                this.title_msg = data[3];
                this._metaFields = data[4];
                if (data[5] && this.nameMeasure && this.nameMeasure.trim()) {
                    const displayNameMap = new Map<number, string>();
                    const concatNameMeasure = ` : ${this.nameMeasure}`;
                    data[5]
                        .toMap()
                        .forEach((value: string, key: number) =>
                            displayNameMap.set(key, value + concatNameMeasure)
                        );
                    this._titleDisplayName = new MultilingualStringBuilder()
                        .fromMap(displayNameMap)
                        .build();
                } else {
                    this._titleDisplayName = data[5];
                }

                let workflowState$: Observable<string> = of('');
                let createdMessage$: Observable<string> = of('');
                let updatedMessage$: Observable<string> = of('');
                const wfState = this.getWfStateField(this.metaFields);

                if (wfState) {
                    this._hasWorkflow = true;
                    const workflowStateFieldName = wfState.getSystemName();
                    const workflowStateCompoundName = wfState.getCompoundCategorySystemName();
                    const workflowEntity = this.entity
                        ? this.entity.getProperty<Classifier>(
                              workflowStateFieldName
                          ).value
                        : undefined;
                    if (workflowEntity) {
                        workflowState$ = this._classifierService.getEntityName(
                            workflowStateCompoundName,
                            workflowEntity
                        );
                    }
                }
                if (this.getCreatedDate()) {
                    createdMessage$ = this.formattingService
                        .formatDateTime(this.getCreatedDate().valueOf())
                        .pipe(
                            mergeMap((date: string) =>
                                this.messageService.getMessageWithPlaceholder(
                                    'de_header_created',
                                    [date, this.createdUser]
                                )
                            )
                        );
                }
                if (this.getLastUpdatedDate()) {
                    updatedMessage$ = this.formattingService
                        .formatDateTime(this.getLastUpdatedDate().valueOf())
                        .pipe(
                            mergeMap((date: string) =>
                                this.messageService.getMessageWithPlaceholder(
                                    'de_header_modified',
                                    [date, this.lastUpdatedUser]
                                )
                            )
                        );
                }

                return combineLatest(
                    workflowState$,
                    createdMessage$,
                    updatedMessage$,
                    currentLanguageId$
                ).pipe(
                    map(dataNames => {
                        this._workFlowState = dataNames[0];
                        this.createdMessage$.next(dataNames[1]);
                        this.modifiedMessage$.next(dataNames[2]);
                        this.currentLanguageId = dataNames[3];
                        return true;
                    })
                );
            })
        );
    }

    private getWfStateField(metaFields: MetaField[]): MetaField {
        const metaField = metaFields.find((field: MetaField) => {
            return field.getType() === MetaFieldType.WORKFLOW_STATE;
        });
        if (!metaField) {
            this._hasWorkflow = false;
            return undefined;
        } else {
            this._hasWorkflow = true;
            return metaField;
        }
    }
}
