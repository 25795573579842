/**
 * @author tatevik.marikyan
 * @since 12/07/2018
 */
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {MetaField} from "@synisys/idm-kb-service-client-js";

export class HierarchicalSelectSettings {

  constructor(public title: MultilingualString,
              public selectedMetaFields: MetaField[],
              public density: string,
              public controlDesign: string) {
  }
}
