/**
 * @author tatevik.marikyan
 * @since 29/07/2018
 */

export class HierarchicalSearchFilterOptions {

  constructor(public query: string,
              public categories: string[],
              public languageId: number,
              public level?: number) {
  }
}
