import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {KbService, MetaCategoryId, MetaField, MetaFieldId, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';

import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {ExtractionService} from '../../services';
import {EntityFieldViewerSettingsComponent} from './entity-field-viewer-settings.component';
import {fullParse} from '../utilities';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {takeUntil} from 'rxjs/operators';

@Component({
             moduleId   : module.id + '',
             selector   : 'entity-field-viewer',
             templateUrl: 'entity-field-viewer.component.html',
           })
@ControlMetadata({
                   cellCount   : 2,
                   name        : 'Entity Field Viewer',
                   settings    : {
                     main: EntityFieldViewerSettingsComponent,
                   },
                   template    : `
                   <entity-field-viewer [id]="id" [value]="%{value}" [viewFieldSystemName]="%{viewFieldSystemName}"
                   [categoryName]="%{categoryName}">
                   </entity-field-viewer>
                   `,
                   isFieldBound: true,
                 })
export class EntityFieldViewerComponent extends AbstractDestructionSubject implements OnInit, OnDestroy, OnChanges {
  @Input()
  public id: string;
  @Input()
  public value: Entity;
  @Input()
  public viewFieldSystemName: string;
  @Input()
  public categoryName: string;

  public metaFieldType = MetaFieldType;
  public field: MetaField;
  private currentLanguage: Language;

  public constructor(private extractionService: ExtractionService,
                     private currentLanguageProvider: CurrentLanguageProvider,
                     private kbService: KbService) {
    super();
  }

  public ngOnInit(): void {
    this.viewFieldSystemName = fullParse(this.viewFieldSystemName);
    if (this.viewFieldSystemName) {
      const metaFieldId = new MetaFieldId(new MetaCategoryId(this.categoryName), this.viewFieldSystemName);
      zip(
        this.currentLanguageProvider.getCurrentLanguage(),
        this.kbService.getMetaFieldByMetaFieldId(metaFieldId),
      ).pipe(takeUntil(this.destroySubject$))
       .subscribe((data: [Language, MetaField]) => {
                    this.currentLanguage = data[0];
                    this.field = data[1];
                  }, err => console.error(err),
       );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('viewFieldSystemName')) {
      this.viewFieldSystemName = fullParse(changes['viewFieldSystemName'].currentValue);
      if (this.viewFieldSystemName) {
        const metaFieldId = new MetaFieldId(new MetaCategoryId(this.categoryName), this.viewFieldSystemName);
        this.kbService.getMetaFieldByMetaFieldId(metaFieldId)
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((metaField: MetaField) => {
              this.field = metaField;
            }, err => console.error(err));
      }
    }
  }

  public extractClassifier(): Observable<string> {
    return this.extractionService
               .extractNameFromClassifier(this.value.getProperty<Entity>(this.field.getSystemName()).value,
                                          this.field.getCompoundCategorySystemName(),
                                          this.currentLanguage.getId());
  }

  public extractMultiSelect(): Observable<string> {
    return this.extractionService
               .extractNameFromMultiSelect(this.value.getProperty<Entity[]>(this.field.getSystemName()).value,
                                           this.field.getCompoundCategorySystemName(),
                                           this.currentLanguage.getId());
  }
}
